export default [
  {
    path: '/admin/:site/',
    name: 'admin.home',
    component: () => import('@/views/admin/Index.vue'),
    children: [
      {
        path: 'organization',
        name: 'admin.organization',
        component: () => import('@/views/admin/organization/Index.vue')
      },
      {
        path: 'rbac/permission',
        name: 'admin.rbac.permission',
        component: () => import('@/views/admin/rbac/Permission.vue')
      },
      {
        path: 'rbac/role',
        name: 'admin.rbac.role',
        component: () => import('@/views/admin/rbac/Role.vue')
      },
      {
        path: 'rbac/user',
        name: 'admin.rbac.user',
        component: () => import('@/views/admin/rbac/User.vue')
      },
      {
        path: 'system/site',
        name: 'admin.system.site',
        component: () => import('@/views/admin/system/Site.vue')
      },
      {
        path: 'system/sms/template',
        name: 'admin.system.sms.template',
        component: () => import('@/views/admin/system/sms/Template.vue')
      },
      {
        path: 'system/module',
        name: 'admin.system.module',
        component: () => import('@/views/admin/system/Module.vue')
      },
      {
        path: 'system/module/:module/menuitem/',
        name: 'admin.system.menuitem',
        component: () => import('@/views/admin/system/Menu/Index.vue')
      },
      {
        path: 'safety/backup',
        name: 'admin.safety.backup',
        component: () => import('@/views/admin/safety/Backup.vue')
      },
      {
        path: 'safety/log',
        name: 'admin.safety.log',
        component: () => import('@/views/admin/safety/Log.vue')
      },
      {
        path: 'safety/audit/system',
        name: 'admin.safety.audit.system',
        component: () => import('@/views/admin/safety/Audit.vue')
      },
      {
        path: 'safety/audit/business',
        name: 'admin.safety.audit.business',
        component: () => import('@/views/admin/safety/Audit.vue')
      }
    ]
  }
]
