import Vue from 'vue'

export default {
  namespaced: true,
  state: {
    properties: false
  },
  mutations: {
    setProperties(state, properties) {
      Vue.set(state, 'properties', properties)
    },
    reset(state) {
      Vue.set(state, 'properties', false)
    }
  },
  actions: {
    async fetch({ commit }) {
      let result = {}
      try {
        const res = await this._vm.$axios.get('/common/site/main/properties/')
        let properties = res.data ? res.data : []
        await Promise.all(
          properties.map(property =>
            this._vm.$storage['properties'].setItem(
              `main-${property.property_id}`,
              property
            )
          )
        )
        properties.map(property => {
          result[property.property_id] = property
        })
        commit('setProperties', result)
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error('Global Property Cache Store fetch failed', e)
        return e
      }
      return result
    },
    reset({ commit }) {
      commit('reset')
    }
  }
}
