import Vue from 'vue'

export default {
  namespaced: true,
  state: {
    schemas: {}
  },
  mutations: {
    setSchemas(state, payload) {
      Vue.set(state.schemas, payload.id, payload.schema)
    },
    reset(state) {
      Vue.set(state, 'schemas', {})
    }
  },
  actions: {
    async fetch({ commit, state }, id) {
      if (state.schemas?.[id]) {
        return state.schemas[id]
      }
      let payload = { id }
      try {
        const res = await this._vm.$axios(`/common/schema/${id}/default`)
        payload.schema = res.data ? res.data : null
        commit('setSchemas', payload)
        return payload.schema
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error('Schema Cache Store fetch failed', e)
        return e
      }
    },
    reset({ commit }) {
      commit('reset')
    }
  },
  getters: {
    schema: state => id => state.schemas?.[id]
  }
}
