export default {
  namespaced: true,
  state: {
    exception: null,
    presets: null
  },
  mutations: {
    setException(state, exception) {
      state.exception = exception
    },
    setPresets(state, presets) {
      state.presets = presets
    },
    reset(state) {
      state.exception = null
      state.presets = null
    }
  },
  actions: {
    async fetch({ commit }, routeName) {
      commit('reset')

      let response
      let url =
        routeName.indexOf('hospital') === 0
          ? '/hospital/setting/preset/'
          : '/project/preset/'
      try {
        response = await this._vm.$axios.get(url, {
          params: { route_name: routeName, pageSize: 1000 }
        })
      } catch (error) {
        const message =
          typeof error.data === 'string'
            ? error.data
            : JSON.stringify(error.data)
        commit('setException', {
          title: '无法获取预置模版信息',
          message
        })
        return
      }
      let presets = response.data?.data ?? null

      commit('setPresets', presets)
      return presets
    },
    reset({ commit }) {
      commit('reset')
    }
  }
}
