import PouchDB from 'pouchdb'
export default {
  methods: {
    getAttachments(files, type) {
      const getContentFromBlob = result => {
        return new Promise((resolve, reject) => {
          if (result.status == 'fulfilled') {
            var fr = new FileReader()
            fr.onloadend = () => {
              // eslint-disable-next-line no-console
              // console.log('file reader end', fr)
              resolve(fr.result)
            }
            fr.onerror = reject
            if (type == 'text') {
              fr.readAsText(result.value)
            } else {
              fr.readAsDataURL(result.value)
            }
          } else {
            reject()
          }
        })
      }
      const db = new PouchDB('config')
      const docId = 'themes:admin'
      const isArray = Array.isArray(files)
      files = isArray ? files : [files]
      // this.$warn('getAttachments', files)
      return new Promise((resolve, reject) => {
        Promise.allSettled(files.map(file => db.getAttachment(docId, file)))
          .then(results => {
            // eslint-disable-next-line no-console
            // this.$warn('attachments', results, files)
            Promise.allSettled(
              results.map(result => getContentFromBlob(result))
            )
              .then(responses => {
                let urls = responses.map(response =>
                  response?.status == 'fulfilled' ? response.value : false
                )
                // eslint-disable-next-line no-console
                // console.log('读取文件内容', urls, files)
                resolve(isArray ? urls : urls[0])
              })
              .catch(err => {
                // eslint-disable-next-line no-console
                // console.warn('无法读取文件内容', err, files)
                reject(err)
              })
          })
          .catch(err => {
            // eslint-disable-next-line no-console
            console.warn('无法获取themes配置', docId, files, err)
            reject(err)
          })
      })
    }
  }
}
