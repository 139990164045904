export default {
  methods: {
    ability: function() {
      let args = [...arguments]
      let permissions = this.$store.getters['SiteStore/permissions']
      if (!permissions) {
        return false
      }
      let result = args.filter(permission => permissions.includes(permission))
      return result.length > 0
    },
    abilityNum: function() {
      let args = [...arguments]
      let permissions = this.$store.getters['SiteStore/permissions']
      if (!permissions) {
        return false
      }
      let result = args.filter(permission => permissions.includes(permission))
      return result.length
    },
    checkProjectStatus() {
      if (this.$route.params.site) {
        const sites = this.$store.getters['UserStore/sites']
        const site = sites.find(item => item.id === this.$route.params.site)
        if (site && site.type === 'project' && site.status !== '已审核') {
          return false
        }
      }
      return true
    }
  }
}
