import Editor from '@/components/Common/Property/Util/Editor'
export default {
  install: Vue => {
    Vue.mixin({
      methods: {
        $showEditor(payload) {
          const ComponentClass = Vue.extend(Editor)
          let node = document.createElement('div')
          try {
            let succeeded = false
            if (payload?.boundary) {
              let el = document.querySelector(payload.boundary)
              if (el) {
                el.appendChild(node)
                succeeded = true
              }
            }
            if (!succeeded) {
              if (
                this.$el.firstChild &&
                this.$el.firstChild.nodeName != '#comment'
              ) {
                this.$el.firstChild.appendChild(node)
              } else {
                this.$el.parentElement.appendChild(node)
              }
            }
          } catch (err) {
            // eslint-disable-next-line no-console
            console.error(
              '附加Editor出错',
              err,
              this.$el,
              this.$el.firstChild,
              this.$el.firstChild.nodeName,
              node
            )
          }

          let instance = new ComponentClass({
            el: node,
            parent: this
          })
          if (payload.listeners) {
            for (let listener of Object.keys(payload.listeners)) {
              instance.$on(listener, payload.listeners[listener])
            }
          }
          instance.$mount()
          instance.show(payload)
          return instance
        }
      }
    })
  }
}
