export default {
  namespaced: true,
  state: {
    modules: [],
    permissions: [],
    roles: [],
    id: null,
    site: null,
    exception: null
  },
  mutations: {
    modules(state, modules) {
      state.modules = modules
      if (this._vm?.$storage?.['user']) {
        this._vm.$storage['user'].setItem('modules', state.modules)
      }
    },
    permissions(state, permissions) {
      state.permissions = permissions
      if (this._vm?.$storage?.['user']) {
        this._vm.$storage['user'].setItem('permissions', state.permissions)
      }
    },
    roles(state, roles) {
      state.roles = roles
      if (this._vm?.$storage?.['user']) {
        this._vm.$storage['user'].setItem('roles', state.roles)
      }
    },
    id(state, id) {
      state.id = id
      if (this._vm?.$storage?.['user']) {
        this._vm.$storage['user'].setItem('siteId', id)
        this._vm.$storage['app'].setItem('last-visited-site', id)
      }
    },
    setSite(state, site) {
      state.site = site
      if (this._vm?.$storage?.['user']) {
        this._vm.$storage['user'].setItem('site', state.site)
      }
    },
    setSiteLogoUrl(state, url) {
      state.site.logo_url = url
      if (this._vm?.$storage?.['user']) {
        this._vm.$storage['user'].setItem('site', state.site)
      }
    },
    setException(state, exception) {
      state.exception = exception
    },
    reset(state) {
      state.modules = null
      state.permissions = null
      state.roles = null
      state.id = null
      state.exception = null
      state.site = null
      if (this._vm?.$storage?.['user']) {
        this._vm.$storage['user'].removeItem('modules')
        this._vm.$storage['user'].removeItem('permissions')
        this._vm.$storage['user'].removeItem('roles')
        this._vm.$storage['user'].removeItem('siteId')
        this._vm.$storage['user'].removeItem('site')
      }
    }
  },
  actions: {
    setSiteById({ commit }, id) {
      const site = this.getters['UserStore/site'](id)
      commit('setSite', site)
    },
    async fetch({ commit, state }) {
      if (state.site.type !== 'project') {
        // eslint-disable-next-line no-console
        console.warn('[SiteStore::fetch] fetch, 目前仅支持项目更新')
        return false
      }
      let response, site
      try {
        response = await this._vm.$axios.get(
          `/project/common/project/${state.site.id}`
        )
        site = response.data
        let templateId = site.project_template_id
        let template
        if (templateId) {
          let properties = await this.$getProperties(
            [templateId],
            site,
            'hospital'
          )
          template = properties[templateId]
          // eslint-disable-next-line no-console
          console.log('当前项目的项目模版', template)
        } else {
          // eslint-disable-next-line no-console
          console.error('当前项目没有项目模版ID', this.site)
        }
        site.template = template
      } catch (error) {
        const message =
          typeof error.data === 'string'
            ? error.data
            : JSON.stringify(error.data)
        commit('setException', {
          title: '无法获取项目信息',
          message
        })
        return false
      }
      commit('setSite', site)
      commit('UserStore/updateSite', site, {
        root: true
      })
      return site
    }
  },
  getters: {
    permissions: state => {
      return state.permissions
    },
    roles: state => {
      return state.roles
    },
    menuitems: state => {
      let menuitems = []
      if (state.modules) {
        for (let i = 0; i < state.modules.length; i++) {
          let module = state.modules[i]
          if (module?.visible !== 1) {
            continue
          }
          if (Array.isArray(module.menuitems) && module.menuitems.length > 0) {
            menuitems = menuitems.concat([
              {
                id: module.id,
                title: true,
                name: module.name,
                classname: '',
                wrapper: {
                  element: 'span',
                  attributes: module.attributes
                }
              }
            ])
            menuitems = menuitems.concat(module.menuitems)
          }
        }
        return menuitems
      }
      return null
    },
    modules: state => {
      return state.modules
    },
    id: state => {
      return state.id
    },
    site: state => state.site
  }
}
