export default {
  namespaced: true,
  state: {
    sites: false
  },
  mutations: {
    fetch(state, sites) {
      state.sites = sites
    }
  },
  actions: {
    // 更新口令牌
    fetch({ commit, state }, force) {
      if (state.sites && !force) {
        return false
      }
      this._vm.$axios
        .get('/common/site/')
        .then(response => {
          commit('fetch', response.data)
        })
        .catch(error => {
          // eslint-disable-next-line no-console
          console.error(error)
        })
    }
  },
  getters: {
    sites: state => {
      return state.sites || []
    }
  }
}
