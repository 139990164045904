import Vue from 'vue'

export default {
  namespaced: true,
  state: {
    names: [],
    ids: [],
    properties: false,
    sites: [],
    currentSite: null
  },
  mutations: {
    setProperties(state, payload) {
      if (state.properties === false) {
        state.properties = {}
      }
      Vue.set(state.properties, payload.site, payload.properties)
    },
    setNames(state, names) {
      Vue.set(state, 'names', names)
    },
    setIds(state, ids) {
      Vue.set(state, 'ids', ids)
    },
    reset(state) {
      state.names = []
      state.ids = []
      state.properties = false
      state.sites = []
      state.currentSite = null
      this._vm.$storage['properties'].clear()
    }
  },
  actions: {
    // eslint-disable-next-line no-empty-pattern
    async fetchProjectProperties({}, siteId) {
      let result = {}
      try {
        const res = await this._vm.$axios.get(
          `/common/site/${siteId}/properties/`
        )
        let properties = res.data ? res.data : []

        properties.map(property => {
          result[property.property_id] = property
        })
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error('Project Property Cache Store fetch failed', e)
        return e
      }
      return result
    },
    async fetch({ commit, state, rootState }, siteId) {
      let site = siteId
      if (typeof site !== 'object') {
        site = (rootState.UserStore?.sites || []).find(
          item => item.id === siteId
        )
      }
      let requests = []
      let result = {}
      if (rootState.Common.Property.Global.properties === false) {
        requests.push(this.dispatch('Common/Property/Global/fetch'))
      } else {
        result['global'] = rootState.Common.Property.Global.properties
      }

      if (site.type === 'hospital') {
        if (!rootState.Common.Property.Hospital.properties[site.id]) {
          requests.push(
            this.dispatch('Common/Property/Hospital/fetch', site.id)
          )
        } else {
          result['hospital'] =
            rootState.Common.Property.Hospital.properties[site.id]
        }
      }
      if (site.type === 'project') {
        if (!rootState.Common.Property.Hospital.properties[site.parent_id]) {
          requests.push(
            this.dispatch('Common/Property/Hospital/fetch', site.parent_id)
          )
        } else {
          result['hospital'] =
            rootState.Common.Property.Hospital.properties[site.parent_id]
        }
        requests.push(
          this.dispatch('Common/Property/Cache/fetchProjectProperties', site.id)
        )
      }

      let results = []
      if (requests.length) {
        results = await Promise.all(requests)
      }
      let properties = {}
      let offset = 0
      if (!result['global']) {
        properties = Object.assign(properties, results[offset])
        offset++
      } else {
        properties = Object.assign(properties, result['global'])
      }

      if (!result['hospital']) {
        properties = Object.assign(properties, results[offset])
        offset++
      } else {
        properties = Object.assign(properties, result['hospital'])
      }

      if (site.type === 'project') {
        properties = Object.assign(properties, results[offset])
      }
      await Promise.all(
        Object.values(properties).map(property =>
          this._vm.$storage['properties'].setItem(
            `${siteId}-${property.property_id}`,
            property
          )
        )
      )

      commit('setProperties', {
        site: siteId,
        properties
      })
      let sortedProperties = Object.values(properties).sort(
        (a, b) => b.priority - a.priority
      )
      let names = sortedProperties.map(property => property.name)
      let ids = sortedProperties.map(property => property.property_id)
      this._vm.$storage['ordered-properties']
        .setItem(`ordered-properties-${siteId}`, {
          names,
          ids
        })
        .catch(function(err) {
          // eslint-disable-next-line no-console
          console.error(err)
        })
      state.names = names
      state.ids = ids
      return properties
    },
    async getOrderedProperties({ commit, rootState }) {
      let site = rootState.SiteStore.id

      try {
        let properties = await this._vm.$storage['ordered-properties'].getItem(
          `ordered-properties-${site}`
        )
        commit('setNames', properties?.names || [])
        commit('setIds', properties?.ids || [])
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error(e)
      }
    },
    async getProperties({ rootState }, ids) {
      if (!Array.isArray(ids) || ids.length === 0) {
        return {}
      }
      let site = rootState.SiteStore.id
      if (typeof ids[0] === 'object') {
        site = ids[0].id
        ids = ids.slice(1)
      }
      ids = ids.map(id => `${site}-${id}`)
      let properties = {}
      try {
        properties = await this._vm.$storage['properties'].getItems(ids)
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error(e)
      }
      let result = {}
      if (Object.keys(properties).length > 0) {
        for (let key in properties) {
          let newKey = key.substr(site.length + 1)
          result[newKey] = properties[key]
        }
      }
      return result
    },
    reset({ commit }) {
      commit('reset')
      commit('Common/Property/Hospital/reset', null, {
        root: true
      })
      commit('Common/Property/Global/reset', null, {
        root: true
      })
    }
  },
  getters: {
    properties: state => state.properties,
    propertyById: state => id => state.properties[id],
    names: state => state.names,
    ids: state => state.ids
  }
}
