export default {
  namespaced: true,
  state: {
    selected: []
  },
  mutations: {
    setSelected(state, selected) {
      state.selected = selected
    },
    reset(state) {
      state.selected = []
    }
  }
}
