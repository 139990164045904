import PouchDB from 'pouchdb'
import { BroadcastChannel, createLeaderElection } from 'broadcast-channel'
import EventBus from '@/core/eventbus'
import pouchDbFetch from '@/core/vendor/zdbx/fetch'
import axios from 'axios'

export default {
  namespaced: true,
  state: {
    exception: null,
    channel: null,
    elector: null,
    leader: false,
    couchdbEndpoint: null,
    handlers: {}
  },
  mutations: {
    setChannel(state, channel) {
      state.channel = channel
    },
    setLeader(state, leader) {
      state.leader = leader
    },
    setCouchDBEndpoint(state, couchdbEndpoint) {
      state.couchdbEndpoint = couchdbEndpoint
    },
    reset(state) {
      state.exception = null
      state.channel = null
      state.elector = null
      state.couchdbEndpoint = null
      state.leader = false
      state.handlers = {}
    }
  },
  actions: {
    initialize({ commit, dispatch }) {
      const channel = new BroadcastChannel('biobank-couchdb')
      commit('setChannel', channel)
      // eslint-disable-next-line no-console
      channel.addEventListener('message', payload => {
        // eslint-disable-next-line no-console
        // console.warn('channel on message', payload)
        if (payload.eventId && payload.source === 'pouchdb') {
          EventBus.$emit(`pouchdb/${payload.eventId}`, payload.doc ?? null)
        }
        if (payload.eventType && payload.source === 'authorization') {
          EventBus.$emit(`authorization/${payload.eventType}`, null)
        }
        if (payload.eventType && payload.source === 'broadcast') {
          this._vm.$warn('接收到广播任务', payload)
          if (payload.eventType === 'reload') {
            window.location.reload()
          }
          if (payload.eventType === 'swal2-close') {
            // eslint-disable-next-line no-console
            console.warn('swal2-close', payload)
            let element = document.querySelector(`.${payload.container}`)

            if (element) {
              element.remove()
            }
          }
        }
      })
      // channel.onmessage = payload => {
      //   this._vm.$error('接收到消息', payload)
      // }
      const elector = createLeaderElection(channel)

      elector.awaitLeadership().then(() => {
        // eslint-disable-next-line no-console
        console.warn('awaitLeadership this tab is now leader')
        commit('setLeader', true)

        // eslint-disable-next-line no-console
        this._vm.$error('当前标签页面变成Leader，开始进行后台同步')
        dispatch('syncMetadata')
      })
    },
    // eslint-disable-next-line no-unused-vars
    async destroy({ state }, dbNames) {
      let result = await indexedDB.databases()

      let names = result
        .map(item => item.name)
        .filter(name => name.indexOf('_pouch_') === 0)

      // eslint-disable-next-line no-console
      console.log('PouchDB的数据库有', result, names)
      if (dbNames) {
        names = names.filter(name => {
          return dbNames.reduce((acc, cur) => {
            return acc || name.indexOf(cur) >= 0
          }, false)
        })
      }
      // var req = indexedDB.deleteDatabase(name);
      // eslint-disable-next-line no-console
      console.log('删除数据库', names)

      let promises = names.map(name => {
        return new Promise((resolve, reject) => {
          if (state.handlers[name]) {
            state.handlers[name].cancel()
            delete state.handlers[name]
          }
          var req = indexedDB.deleteDatabase(name)
          req.onsuccess = () => {
            resolve()
            // this._vm.$error('Deleted database successfully', res)
            // eslint-disable-next-line no-console
            console.log('数据库已经成功删除')
          }
          req.onerror = () => {
            reject()
            // eslint-disable-next-line no-console
            console.log('Couldnt delete database')
          }
          req.onblocked = () => {
            reject()
            // eslint-disable-next-line no-console
            console.log(
              'Couldnt delete database due to the operation being blocked'
            )
          }
        })
      })
      return Promise.all(promises)

      //   localDb
      //     .allDocs()
      //     .then(function (result) {
      //       // Promise isn't supported by all browsers; you may want to use bluebird
      //       return Promise.all(
      //         result.rows.map(function (row) {
      //           return localDb.remove(row.id, row.value.rev)
      //         })
      //       )
      //     })
      //     .then(function () {
      //       localDb.compact().then(() => {
      //         resolve()
      //       })
      //     })
      //     .catch(function (err) {
      //       reject(err)
      //     })
      // })
    },
    // 用于同步一次，非长期保持连接
    replicateOnce({ state }, payload) {
      const { dbName, options } = payload

      let remoteDB = new PouchDB(`${state.couchdbEndpoint}${dbName}`, {
        fetch: pouchDbFetch
      })

      // eslint-disable-next-line no-console
      console.warn('replicateOnce 开始同步本地数据库', dbName)
      return PouchDB.replicate(remoteDB, dbName, {
        retry: options?.retry ?? true,
        batch_size: options?.batch_size ?? 1000,
        batch_limit: options?.batch_limit ?? 100
      })
    },
    syncMetadata({ state }) {
      if (!state.leader) {
        // eslint-disable-next-line no-console
        console.warn('当前标签页面不是Leader，跳过同步')
        return
      }
      let jwtToken = localStorage.getItem('jwtToken') ?? null

      let dbNames = []
      if (!state.handlers['config']) {
        dbNames.push('config')
      }
      if (jwtToken && !state.handlers['properties']) {
        dbNames.push('properties')
      }

      if (!dbNames.length) {
        // eslint-disable-next-line no-console
        console.warn('没有找到需要同步的数据库', state)
        return
      }
      this._vm.$warn('[Daemon/syncMetadata] 当前需要同步的数据库', dbNames)
      let promises = dbNames.map(
        dbName =>
          new Promise((resolve, reject) => {
            let remoteDB = new PouchDB(`${state.couchdbEndpoint}${dbName}`, {
              fetch: pouchDbFetch
            })
            state.handlers[dbName] = PouchDB.replicate(remoteDB, dbName, {
              live: axios.defaults.encryptModule ? false : true,
              retry: axios.defaults.encryptModule ? false : true,
              batch_size: 1000,
              batch_limit: 100
            })
              .on('change', info => {
                // eslint-disable-next-line no-console
                console.warn('pouchdb changed', dbName, info)
                let { docs } = info
                docs = docs ?? []
                for (let doc of docs) {
                  const eventId = `${dbName}/${doc._id}`
                  this._vm.$warn('发现metadata发生变化', eventId, state.channel)
                  EventBus.$emit(`pouchdb/${eventId}`, doc ?? null)
                  state.channel.postMessage({ eventId, doc, source: 'pouchdb' })
                }
              })
              .on('paused', () => {
                // eslint-disable-next-line no-console
                console.log('pouchdb paused', dbName)
                resolve()
              })
              .on('active', info => {
                // eslint-disable-next-line no-console
                console.log('pouchdb active', dbName, info)
              })
              .on('denied', err => {
                // eslint-disable-next-line no-console
                console.log('pouchdb denied', dbName, err)
                reject(err)

                if (state.handlers[dbName]) {
                  delete state.handlers[dbName]
                }
              })
              .on('complete', info => {
                // eslint-disable-next-line no-console
                console.log('pouchdb complete', dbName, info)
                // initIndexes(dbName)
                resolve(info)
              })
              .on('error', err => {
                // eslint-disable-next-line no-console
                console.log('pouchdb error', dbName, err)
                reject(err)
                if (state.handlers[dbName]) {
                  delete state.handlers[dbName]
                }
              })
          })
      )
      return Promise.all(promises)
    },
    // eslint-disable-next-line no-unused-vars
    async getDocumentById({ state }, payload) {
      let { dbName, key, options } = payload

      // eslint-disable-next-line no-console
      // console.log('getDocumentById', payload, state.leader)
      const db = new PouchDB(dbName)
      options = options ?? {}
      try {
        let result = await db.get(key, options)
        if (result._conflicts) {
          // eslint-disable-next-line no-console
          console.warn(`${key}版本有冲突`, key)
        }

        return result
      } catch (err) {
        // eslint-disable-next-line no-console
        console.warn('getDocumentById', dbName, key, err)
      }
      return null
    },
    // eslint-disable-next-line no-unused-vars
    async getDocuments({ state }, payload) {
      // eslint-disable-next-line no-console
      // console.log('getDocuments', payload, state.leader)
      const { dbName, keys, options } = payload
      const db = new PouchDB(dbName)
      try {
        let params = Object.assign({ include_docs: true, keys }, options)
        let result = await db.allDocs(params)
        // eslint-disable-next-line no-console
        // console.warn('getDocuments', result)
        let documents = []
        if (Array.isArray(result?.rows)) {
          documents = result.rows.filter(row => !row.error).map(row => row.doc)
        }
        return documents
      } catch (err) {
        // eslint-disable-next-line no-console
        console.warn('getDocuments', err)
      }
      return null
    },
    // eslint-disable-next-line no-unused-vars
    async setDocumentById({ state }, payload) {
      const { dbName, key, document } = payload
      const db = new PouchDB(dbName)
      // eslint-disable-next-line no-console
      console.log(dbName, key, document)
      let result
      try {
        result = await db.get(key)
        document._rev = result._rev
      } catch (err) {
        // eslint-disable-next-line no-console
        console.warn('setDocumentById get document', err)
      }
      try {
        document._id = key
        await db.put(JSON.parse(JSON.stringify(document)))
      } catch (err) {
        // eslint-disable-next-line no-console
        console.warn('setDocumentById update document', err)
        return false
      }
      return document
    },
    // eslint-disable-next-line no-unused-vars
    async removeDocument({ state }, payload) {
      const { dbName, document } = payload
      const db = new PouchDB(dbName)
      try {
        let doc = await db.get(document._id)
        document._rev = doc._rev
      } catch (err) {
        // eslint-disable-next-line no-console
        console.warn('removeDocument get document', err)
      }
      try {
        await db.remove(document)
        db.compact()
      } catch (err) {
        // eslint-disable-next-line no-console
        console.warn('removeDocument failed', err, document)
      }
      return null
    },
    reset({ commit }) {
      commit('reset')
    }
  }
}
