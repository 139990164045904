import store from '@/store'
const plugin = require.context('./', false, /\.js$/)
const plugins = {}

plugin.keys().map(item => {
  const name = item.replace(/\.\/(\w+)\.js$/g, '$1')
  if (name === 'index') {
    return
  }
  plugins[name] = require(`${item}`).default
})

export default function install(Vue) {
  if (install.installed) {
    return
  }
  install.installed = true
  for (const key in plugins) {
    if (Object.prototype.hasOwnProperty.call(plugins, key)) {
      Vue.use(plugins[key], { store })
    }
  }
}
