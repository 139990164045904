export default {
  methods: {
    /**
     * source==true 时为修改用的组件；source==false 时使用新增用的组件
     */
    _getComponents(properties, type) {
      if (!properties) {
        return {}
      }
      let propertyComponents = {}

      for (let id in properties) {
        if (!properties[id]?.type) {
          propertyComponents[id] = () =>
            import(`@/components/Common/Property/${type}/Input`)
          continue
        }

        switch (properties[id].type) {
          case '选择型':
            if (type == 'Form') {
              // eslint-disable-next-line no-console
              // console.log('初始化组件类型 ', id, properties[id].attributes)
              if (properties[id]?.attributes?.['类型'] === 'checkbox') {
                propertyComponents[id] = () =>
                  import('@/components/Common/Property/Form/Checkbox')
                continue
              }
              if (properties[id]?.attributes?.['类型'] === 'radio') {
                propertyComponents[id] = propertyComponents[id] = () =>
                  import(`@/components/Common/Property/${type}/Radio`)
                continue
              }
              propertyComponents[id] = () =>
                properties[id]?.attributes?.['服务器端搜索'] === '是'
                  ? import('@/components/Common/Property/Form/RemoteSelect')
                  : import('@/components/Common/Property/Form/Select')
              continue
            }
            if (type === 'Revise') {
              propertyComponents[id] = () =>
                import('@/components/Common/Property/Revise/Select')
              continue
            }
            propertyComponents[id] = () =>
              import('@/components/Common/Property/Viewer/Select')
            continue
          case '上传型':
            propertyComponents[id] = () =>
              import(`@/components/Common/Property/${type}/Uploader`)
            continue
          case '自动补全型':
            propertyComponents[id] = () =>
              import(`@/components/Common/Property/${type}/AutoComplete`)
            continue
          case '文本型':
          case '日期型':
            propertyComponents[id] = () =>
              import(`@/components/Common/Property/${type}/Input`)
            continue
          case '树型':
            if (type === 'Revise') {
              propertyComponents[id] = () =>
                import('@/components/Common/Property/Revise/Select')
              continue
            }
            propertyComponents[id] = () =>
              import(`@/components/Common/Property/${type}/TreeSelect`)
            continue
          case '组织机构型':
            if (type === 'Revise') {
              propertyComponents[id] = () =>
                import('@/components/Common/Property/Revise/Select')
              continue
            }
            propertyComponents[id] = () =>
              import(`@/components/Common/Property/${type}/OrganizationSelect`)
            continue
          case '描述型':
            propertyComponents[id] = () =>
              import('@/components/Common/Property/Form/Content')
            continue
          case '富文本型':
            propertyComponents[id] = () =>
              import('@/components/Common/Property/Form/Html')
            continue
          case '协议型':
            propertyComponents[id] = () =>
              import(`@/components/Common/Property/${type}/Agreement`)
            continue
          case '标签输入':
            propertyComponents[id] = () =>
              import('@/components/Common/Property/Form/Tagging')
            continue
          case '多项输入':
            propertyComponents[id] = propertyComponents[id] = () =>
              import(`@/components/Common/Property/${type}/MultipleTagging`)
            continue
          case '单选型':
            propertyComponents[id] = propertyComponents[id] = () =>
              import(`@/components/Common/Property/${type}/Radio`)
            continue
          case '代码型':
            propertyComponents[id] = propertyComponents[id] = () =>
              import(`@/components/Common/Property/${type}/CodeMirror`)
            continue
          case '滑块型':
            propertyComponents[id] = propertyComponents[id] = () =>
              import(`@/components/Common/Property/${type}/Slider`)
            continue
          case '自定义':
            if (properties[id]?.attributes?.['组件URL']) {
              const componentUrl = properties[id]?.attributes?.['组件URL']
              propertyComponents[id] = () => import(`@/${componentUrl}`)
              continue
            }
            if (properties[id]?.attributes?.['组件'] === 'PropertySelector') {
              propertyComponents[id] = () =>
                import(`@/components/Common/Property/${type}/PropertySelector`)
              continue
            }
            if (properties[id]?.attributes?.['组件'] === 'Weekdays') {
              propertyComponents[id] = () =>
                import(`@/components/Common/Property/${type}/Weekdays`)
              continue
            }
            if (properties[id]?.attributes?.['组件'] === 'Timespans') {
              propertyComponents[id] = () =>
                import(`@/components/Common/Property/${type}/Timespans`)
              continue
            }
            if (properties[id]?.attributes?.['组件'] === 'OrganizationSelect') {
              propertyComponents[id] = () =>
                import(
                  `@/components/Common/Property/${type}/OrganizationSelect`
                )
              continue
            }
            propertyComponents[id] = () =>
              import('@/components/Common/Property/Viewer/Input')
            continue
          default:
            propertyComponents[id] = () =>
              import(`@/components/Common/Property/${type}/Input`)
        }
      }
      // eslint-disable-next-line no-console
      // console.log('返回结果', propertyComponents)
      return propertyComponents
    },
    getReadOnlyComponents(properties) {
      return this._getComponents(properties, 'Viewer')
    },
    getComponents(properties, readOnly, source) {
      const type = readOnly ? 'Viewer' : 'Form'
      return this._getComponents(properties, type, source)
    },
    getFormByComponents(fields) {
      let properties = {}
      let propertyComponents = {}
      let form = {}
      for (let field of fields) {
        let { id, _meta, ...property } = field
        property.property_id = id

        let component = null
        form[id] = null
        if (_meta && _meta.component) {
          let componentUrl = _meta.componentUrl ?? ''
          // this.$warn('getFormByComponents', _meta)
          switch (_meta.component) {
            case 'PropertyFormAppendableSelect':
              component = () =>
                import('@/components/Common/Property/Form/AppendableSelect')
              break
            case 'PropertyFormSelect':
              component = () =>
                import('@/components/Common/Property/Form/Select')
              break
            case 'PropertyFormRadio':
              component = () =>
                import('@/components/Common/Property/Form/Radio')
              break
            case 'PropertyFormInput':
              component = () =>
                import('@/components/Common/Property/Form/Input')
              break
            case 'PropertyFormTreeSelect':
              component = () =>
                import('@/components/Common/Property/Form/TreeSelect')
              break
            case 'PropertyFormRemoteSelect':
              component = () =>
                import('@/components/Common/Property/Form/RemoteSelect')
              break
            case 'PropertyFormOrganizationSelect':
              component = () =>
                import('@/components/Common/Property/Form/OrganizationSelect')
              break
            case 'PropertyFormTagging':
              component = () =>
                import('@/components/Common/Property/Form/Tagging')
              break
            case 'PropertyFormSwitch':
              component = () =>
                import('@/components/Common/Property/Form/Switch')
              break
            case 'PropertyFormUploader':
              component = () =>
                import('@/components/Common/Property/Form/Uploader')
              break
            case 'PropertyFormContent':
              component = () =>
                import('@/components/Common/Property/Form/Content')
              break
            case 'PropertyFormHtml':
              component = () => import('@/components/Common/Property/Form/Html')
              break
            case 'PropertyFormSignature':
              component = () =>
                import('@/components/Common/Property/Form/Signature')
              break
            case 'PropertyFormCheckbox':
              component = () =>
                import('@/components/Common/Property/Form/Checkbox')
              break
            case 'PropertyFormCode':
              component = () => import('@/components/Common/Property/Form/Code')
              break
            case 'PropertyFormEditor':
              this.$warn('PropertyFormEditor')
              component = () =>
                import('@/components/Common/Property/Form/Editor')
              break
            case 'PropertyFormCodeMirror':
              component = () =>
                import('@/components/Common/Property/Form/CodeMirror')
              break
            case 'PropertyFormSlider':
              component = () =>
                import('@/components/Common/Property/Form/Slider')
              break
            case 'PropertyFormFreezerIconPicker':
              component = () =>
                import('@/components/Common/Property/Form/FreezerIconPicker')
              break
            case 'PropertyFormCustom':
              component = () => import(`@/${componentUrl}`)
          }
        } else {
          let propertyComponents = this._getComponents(
            {
              [id]: field
            },
            'Form',
            'custom.editor'
          )
          component = propertyComponents?.[id] ?? null
        }
        if (!component) {
          continue
        }

        propertyComponents[id] = component
        properties[id] = property
      }
      return { properties, propertyComponents, form }
    },
    getViewerByComponents(fields) {
      let properties = {}
      let propertyComponents = {}
      let form = {}
      for (let field of fields) {
        let { id, _meta, ...property } = field
        property.property_id = id

        let component = null
        form[id] = null
        if (_meta && _meta.component) {
          switch (_meta.component) {
            case 'PropertyFormSelect':
              component = () =>
                import('@/components/Common/Property/Viewer/Select')
              break
            case 'PropertyFormRadio':
              component = () =>
                import('@/components/Common/Property/Viewer/Radio')
              break
            case 'PropertyFormInput':
              component = () =>
                import('@/components/Common/Property/Viewer/Input')
              break
            case 'PropertyFormTreeSelect':
              component = () =>
                import('@/components/Common/Property/Viewer/TreeSelect')
              break
            case 'PropertyFormRemoteSelect':
              component = () =>
                import('@/components/Common/Property/Viewer/Select')
              break
            case 'PropertyFormOrganizationSelect':
              component = () =>
                import('@/components/Common/Property/Viewer/OrganizationSelect')
              break
            case 'PropertyFormTagging':
              component = () =>
                import('@/components/Common/Property/Viewer/Input')
              break
            case 'PropertyFormAutoComplete':
              component = () =>
                import('@/components/Common/Property/Form/AutoComplete')
              break
            case 'PropertyFormContent':
              component = () =>
                import('@/components/Common/Property/Form/Content')
              break
            case 'PropertyFormHtml':
              component = () => import('@/components/Common/Property/Form/Html')
              break
            case 'PropertyFormSignature':
              component = () =>
                import('@/components/Common/Property/Form/Signature')
              break
            case 'PropertyFormCheckbox':
              component = () =>
                import('@/components/Common/Property/Form/Checkbox')
              break
            case 'PropertyFormCodeMirror':
              component = () =>
                import('@/components/Common/Property/Viewer/CodeMirror')
              break
            case 'PropertyFormSlider':
              component = () =>
                import('@/components/Common/Property/Viewer/Slider')
              break
          }
        } else {
          let propertyComponents = this._getComponents(
            {
              [id]: field
            },
            'Viewer',
            'custom.viewer'
          )
          component = propertyComponents?.[id] ?? null
        }
        if (!component) {
          continue
        }

        propertyComponents[id] = component
        properties[id] = property
      }
      return { properties, propertyComponents, form }
    },
    getDefaultValue(properties) {
      let form = {}
      for (let property of Object.values(properties)) {
        if (property?.value) {
          if (property.value.indexOf('()') === 0) {
            form[property.property_id] = eval(property.value)()
          } else {
            form[property.property_id] = property.value
          }
        } else {
          form[property.property_id] = null
        }
      }
      return form
    }
  }
}
