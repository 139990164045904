const connect = time => {
  return new Promise(resolve =>
    setTimeout(() => {
      resolve()
    }, time)
  )
}

export default {
  install: Vue => {
    Object.defineProperties(Vue.prototype, {
      $connect: {
        get: () => {
          return connect
        }
      }
    })
  }
}
