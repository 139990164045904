import { flattenTree } from '@/core/util'

export default {
  namespaced: true,
  state: {
    roles: localStorage.getItem('rolestore_roles')
      ? JSON.parse(localStorage.getItem('rolestore_roles'))
      : false
  },
  mutations: {
    roles(state, roles) {
      state.roles = roles
    },
    id(state, id) {
      state.id = id
    },
    fetch(state, roles) {
      state.roles = Object.values(roles)
      localStorage.setItem('rolestore_roles', JSON.stringify(state.roles))
    }
  },
  actions: {
    roles({ commit }, roles) {
      commit('roles', roles)
    },
    async fetch({ commit, state }) {
      if (!state.roles) {
        const res = await this._vm.$axios.get('/common/role')
        // eslint-disable-next-line no-console
        // console.warn('RoleStore fetch', res.data)
        commit('fetch', res.data)
      }
      return state.roles
    }
  },
  getters: {
    roles: state => ids => {
      if (!ids) {
        return state.roles
      }

      function findRoles(ids, roles, result) {
        for (let i = 0; i < roles.length; i++) {
          let role = roles[i]
          if (ids.includes(role.id)) {
            let item = JSON.parse(JSON.stringify(role))
            delete item.children
            result.push(item)
          }
          if (role.children && role.children.length) {
            findRoles(ids, role.children, result)
          }
        }
        return result
      }

      let result = []
      findRoles(ids, state.roles, result)
      return result
    },
    children: state => (roleIds, flatten) => {
      if (!state.roles) {
        return false
      }

      function findRole(id, roles) {
        for (let i = 0; i < roles.length; i++) {
          let item = roles[i]
          if (item.id === id) {
            return item
          }
          if (Array.isArray(item.children)) {
            let result = findRole(id, item.children)
            if (result !== false) {
              return result
            }
          }
        }
        return false
      }
      roleIds = Array.isArray(roleIds) ? roleIds : [roleIds]

      let result = []
      for (let id of roleIds) {
        let role = findRole(id, Object.values(state.roles))
        if (flatten) {
          result = result.concat(flattenTree(role))
        } else {
          result = result.concat(role)
        }
      }

      return result
    }
  }
}
