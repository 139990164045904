export default {
  name: 'go-index',
  deep: false,
  bind: (el, binding, vnode) => {
    let node = vnode.context
    while (node && node.$options.name !== 'App') {
      if (typeof node?._data?.previousRoute != 'undefined') {
        break
      }
      node = node.$parent
    }
    // eslint-disable-next-line no-console
    // console.warn('找到父亲节点', node)
    let previousRoute = node?._data?.previousRoute
    // eslint-disable-next-line no-console
    console.warn('找到父亲节点的前一路由', previousRoute)
    let vm = vnode.context
    // if (!previousRoute) {
    //   if (vnode.context.$route.meta?.fallbackRoute?.name) {
    //     vm.$router.push({
    //       name: vm.$route.meta.fallbackRoute.name
    //     })
    //   } else {
    //     vm.$router.go(-1)
    //   }
    //   return
    // }

    if (previousRoute?.meta?.name && previousRoute?.meta?.mandatory) {
      el.innerHTML = `<i class="far fa-chevron-left" /> 返回${previousRoute?.meta?.name}`
    }

    el.addEventListener('click', () => {
      let defaultRouteName = vm.$route?.meta?.fallbackRoute?.name ?? null
      // eslint-disable-next-line no-console
      console.warn('默认路由名称', defaultRouteName)
      if (previousRoute?.name != defaultRouteName && defaultRouteName) {
        if (
          Array.isArray(previousRoute?.meta?.mandatory) &&
          previousRoute.meta.mandatory.includes(vm.$route.name)
        ) {
          const route = {
            name: previousRoute.name,
            params: vm.$clone(previousRoute.params),
            query: vm.$clone(previousRoute.query)
          }
          vm.$router.push(route)
          return
        }
        vm.$router.push({
          name: vm.$route.meta.fallbackRoute.name
        })

        // eslint-disable-next-line no-console
        console.log('返回到默认路由', vm.$route.meta.fallbackRoute?.name)
      } else {
        const route = {
          name: previousRoute.name,
          params: vm.$clone(previousRoute.params),
          query: vm.$clone(previousRoute.query)
        }
        vm.$router.push(route)
        // eslint-disable-next-line no-console
        console.log('返回到上一路由', route)
      }
    })
  }
}
