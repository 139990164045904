export default {
  created() {
    this.$Message.config({
      background: true,
      top: 150,
      duration: 3
    })
  },
  methods: {
    onIviewDatePickerChanged(date, variable) {
      let object = this
      var stack = variable.split('.')

      while (stack.length > 1) {
        object = object[stack.shift()]
      }

      if (Array.isArray(date)) {
        date = date.map(item => {
          return item.replace(/(年|月)+/g, '-').replace('日', '')
        })
      } else {
        date = date.replace(/(年|月)+/g, '-').replace('日', '')
      }
      object[stack.shift()] = date
    },
    updatePaginationData(response, name) {
      this.$set(this[name], 'data', response.data.data)
      this.$set(this[name], 'total', response.data?.total ?? 1)
      this.$nextTick(() => {
        this.$set(this[name], 'current_page', response.data?.current_page ?? 1)
      })
    }
  }
}
