const httpAdapter = require('axios/lib/adapters/http')
const utils = require('axios/lib/utils')
const settle = require('axios/lib/core/settle')
const buildFullPath = require('axios/lib/core/buildFullPath')
const buildURL = require('axios/lib/helpers/buildURL')
import { getIv } from '@/core/util'
import { encrypt, decrypt } from './utility'

const decryptResponse = (response, transformer, reject) => {
  if (response?.headers?.['x-zd']) {
    // eslint-disable-next-line no-console
    // console.log('通过WASM解密', response)
    const iv = response.headers['x-zd']

    let data = ''
    try {
      data = decrypt(transformer, response.headers, response.data, iv)
    } catch (e) {
      reject(e)
    }
    try {
      response.data = data.length > 0 ? JSON.parse(data ?? '') ?? data : ''
    } catch (e) {
      // eslint-disable-next-line no-console
      console.warn('结果不是JSON对象', data)
    }
  }
  return response
}
const encryptAdapter = async config =>
  new Promise((resolve, reject) => {
    let {
      encryptModule: { transformer }
    } = config

    // window.vue.$warn('[encryptAdapter] algorithm', algorithm)

    // eslint-disable-next-line no-console
    // window.vue.$warn('[encryptAdapter] config', config, encrypt)
    let data = config.data

    // 解析URL
    const fullPath = buildFullPath(config.baseURL, config.url)
    const urlObject = new URL(fullPath)
    const path = buildURL(urlObject.pathname, config.params).replace(/^\?/, '')
    // eslint-disable-next-line no-console
    // console.warn('[encryptAdapter] path', path)
    let request = {
      baseUrl: `${urlObject.protocol}//${urlObject.hostname}${
        urlObject.port ? `:${urlObject.port}` : ''
      }`,
      path,
      method: config.method ?? 'get',
      headers: Object.assign({}, config.headers, config.header ?? {})
    }

    if (utils.isFormData(data)) {
      // eslint-disable-next-line no-console
      // console.warn('[encryptAdapter] is form data', config.data)
      let form = {}
      for (let pair of data.entries()) {
        if (pair[1] instanceof File || pair[1] instanceof Blob) {
          // eslint-disable-next-line no-console
          // console.warn('[encryptAdapter] 这是一个文件', pair[0])
          continue
        }
        form[pair[0]] = pair[1]
        config.data.delete(pair[0])
      }
      request.body = form
    } else {
      request.body = data
    }
    const iv = getIv()
    const key = getIv()
    // eslint-disable-next-line no-console
    // console.warn('密钥', key)
    // 如果启动了加密模块
    let { headers, body } = encrypt(transformer, request, key, iv)
    if (utils.isFormData(data)) {
      config.data.append('_', body)
    } else {
      config.data = body
    }
    let sign = transformer.sign_hex(body, false)

    // eslint-disable-next-line no-console
    // console.info('请求', request, config.encryptModule)
    for (let key of Object.keys(headers)) {
      config.headers[key] = headers[key]
    }
    config.headers['X-ZC'] = transformer.sm2_encrypt(sign, false)
    config.headers['X-ZD'] = iv
    // eslint-disable-next-line no-console
    // console.warn('使用加密传输', payload, config.method)
    // 如果是cors请求，不应该覆盖
    config.method = config.method === 'options' ? config.method : 'post'
    config.url = '/web/gateway'
    config.withCredentials = false
    delete config.headers['Authorization']
    delete config.headers['X-SITE-ID']
    delete config.params
    delete config.encryptModule

    return httpAdapter(config)
      .then(response => {
        decryptResponse(response, transformer, reject)
        settle(resolve, reject, response)
      })
      .catch(error => {
        error.response = decryptResponse(error.response, transformer, reject)
        reject(error)
      })
  })

export default encryptAdapter
