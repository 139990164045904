export default [
  {
    path: '/',
    redirect: '/web/auth/login'
  },
  {
    path: '/web/',
    name: 'web.home',
    component: () => import('@/views/web/Index.vue'),
    children: [
      {
        path: 'auth/login',
        name: 'web.login',
        meta: {
          name: '用户登录'
        },
        component: () => import('@/views/web/auth/Login.vue')
      },
      {
        path: 'register/mobile',
        name: 'register.mobile',
        meta: {
          name: '手机注册'
        },
        component: () => import('@/views/web/register/Mobile.vue')
      },
      {
        path: 'register/email',
        name: 'register.email',
        meta: {
          name: '邮箱注册'
        },
        component: () => import('@/views/web/register/Email.vue')
      },
      {
        path: 'register/email/code/:token/',
        name: 'register.email.code',
        meta: {
          name: '邮箱注册（邮箱验证）'
        },
        component: () => import('@/views/web/register/Code.vue')
      },
      {
        path: 'password/email/',
        name: 'password.email',
        meta: {
          name: '邮箱密码重置'
        },
        component: () => import('@/views/web/password/Email.vue')
      },
      {
        path: 'password/email/code/:token/',
        name: 'password.email.code',
        meta: {
          name: '邮箱密码重置（验证）'
        },
        component: () => import('@/views/web/password/Code.vue')
      },
      {
        path: 'password/mobile',
        name: 'password.mobile',
        meta: {
          name: '手机密码重置'
        },
        component: () => import('@/views/web/password/Mobile.vue')
      },
      {
        path: 'user/email/code/:token/',
        name: 'user.email.code',
        meta: {
          name: '验证邮箱绑定'
        },
        component: () => import('@/views/web/user/email/Code.vue')
      },
      {
        path: 'process/service',
        name: 'process.service',
        meta: {
          name: '深加工服务列表'
        },
        component: () => import('@/views/web/process/service/Index.vue')
      },
      {
        path: 'redirect/:code',
        name: 'redirect',
        meta: {
          name: '重定向跳转页'
        },
        component: () => import('@/views/web/redirect/Index.vue')
      }
    ]
  },
  {
    path: '/',
    name: 'index',
    component: () => import('@/views/Index.vue')
  },
  {
    path: '/web/navigator',
    name: 'web.navigator',
    meta: {
      name: 'Google浏览器下载'
    },
    component: () => import('@/views/web/navigator/Index.vue')
  },
  {
    path: '/web/file/:file',
    name: 'web.file',
    meta: {
      name: '文件下载'
    },
    component: () => import('@/views/web/file/Index.vue')
  }
]
