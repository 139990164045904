import { customAlphabet } from 'nanoid'
// 把一棵树拍扁
const flattenTree = node => {
  if (node.children && node.children.length) {
    let item = JSON.parse(JSON.stringify(node))
    delete item.children
    let result = [item]
    for (const child of node.children) {
      result = result.concat(flattenTree(child))
    }
    return result
  }
  return node
}

const flatten = arr => {
  return arr
    ? arr.reduce((result, item) => {
        let clone = JSON.parse(JSON.stringify(item))
        delete clone.children
        return result.concat(clone).concat(flatten(item.children))
      }, [])
    : []
}

// 为选择器重新格式化选项
const normalizerPropertyOption = item => {
  item.value = item.id
  item.label = item.name
  delete item.id
  delete item.name
  item.customProperties = JSON.parse(JSON.stringify(item))
  if (item['拼音']) {
    item.customProperties['pinyin'] = item['拼音']
    delete item['拼音']
  }
  if (item['拼音首字母']) {
    item.customProperties['pinyin_abbr'] = item['拼音首字母']
    delete item['拼音首字母']
  }
  return item
}

// getBreadcrumb
function getBreadcrumb(id, array, breadcrumb) {
  for (let i = 0; i < array.length; i++) {
    let item = array[i]
    breadcrumb.push(item)
    if (item.id === id) {
      return breadcrumb
    }
    if (Array.isArray(item.children)) {
      let result = getBreadcrumb(id, item.children, breadcrumb)
      if (result !== false) {
        return result
      }
    }
    breadcrumb.pop()
  }
  return false
}

function isObjectEquals(x, y) {
  if (x === y) return true
  // if both x and y are null or undefined and exactly the same

  if (!(x instanceof Object) || !(y instanceof Object)) return false
  // if they are not strictly equal, they both need to be Objects

  if (x.constructor !== y.constructor) return false
  // they must have the exact same prototype chain, the closest we can do is
  // test there constructor.

  for (var p in x) {
    if (!Object.prototype.hasOwnProperty.call(x, p)) continue
    // other properties were tested using x.constructor === y.constructor

    if (!Object.prototype.hasOwnProperty.call(y, p)) return false
    // allows to compare x[ p ] and y[ p ] when set to undefined

    if (x[p] === y[p]) continue
    // if they have the same strict value or identity then they are equal

    if (typeof x[p] !== 'object') return false
    // Numbers, Strings, Functions, Booleans must be strictly equal

    if (!isObjectEquals(x[p], y[p])) return false
    // Objects and Arrays must be tested recursively
  }

  for (p in y) {
    if (
      Object.prototype.hasOwnProperty.call(y, p) &&
      !Object.prototype.hasOwnProperty.call(x, p)
    )
      return false
    // allows x[ p ] to be set to undefined
  }
  return true
}

const prettyBytes = num => {
  // jacked from: https://github.com/sindresorhus/pretty-bytes
  if (typeof num !== 'number' || isNaN(num)) {
    throw new TypeError('Expected a number')
  }

  var exponent
  var unit
  var neg = num < 0
  var units = ['B', 'kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']

  if (neg) {
    num = -num
  }

  if (num < 1) {
    return (neg ? '-' : '') + num + ' B'
  }

  exponent = Math.min(
    Math.floor(Math.log(num) / Math.log(1000)),
    units.length - 1
  )
  num = (num / Math.pow(1000, exponent)).toFixed(2) * 1
  unit = units[exponent]

  return (neg ? '-' : '') + num + ' ' + unit
}

const toDayJsFormat = format => {
  format = format.replace(/yyyy/g, 'YYYY')
  format = format.replace(/dd/g, 'DD')
  return format
}

const deepCopy = obj => {
  if (typeof obj !== 'object' || obj === null) {
    return obj
  }

  if (obj instanceof Date) {
    return new Date(obj.getTime())
  }

  if (obj instanceof Array) {
    return obj.reduce((arr, item, i) => {
      arr[i] = deepCopy(item)
      return arr
    }, [])
  }

  if (obj instanceof Object) {
    return Object.keys(obj).reduce((newObj, key) => {
      newObj[key] = deepCopy(obj[key])
      return newObj
    }, {})
  }
}

// 将dataUrl转换为blob对象
const dataURLtoBlob = dataurl => {
  let arr = dataurl.split(',')
  let mime = arr[0].match(/:(.*?);/)[1]
  let bstr = atob(arr[1])
  let n = bstr.length
  let u8arr = new Uint8Array(n)
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n)
  }
  return new Blob([u8arr], { type: mime })
}

function stringTemplateFormat(string) {
  var nargs = /\{([0-9a-zA-Z_]+)\}/g
  var args
  if (arguments.length === 2 && typeof arguments[1] === 'object') {
    args = arguments[1]
  } else {
    args = new Array(arguments.length - 1)
    for (var i = 1; i < arguments.length; ++i) {
      args[i - 1] = arguments[i]
    }
  }

  if (!args || !args.hasOwnProperty) {
    args = {}
  }

  return string.replace(nargs, function replaceArg(match, i, index) {
    var result

    if (string[index - 1] === '{' && string[index + match.length] === '}') {
      return i
    } else {
      result = Object.prototype.hasOwnProperty.call(args, i) ? args[i] : null
      if (result === null || result === undefined) {
        return ''
      }

      return result
    }
  })
}
function sleep(ms) {
  return new Promise(resolve => setTimeout(resolve, ms))
}

function updateQueryStringParameter(uri, key, value) {
  var re = new RegExp('([?&])' + key + '=.*?(&|$)', 'i')
  var separator = uri.indexOf('?') !== -1 ? '&' : '?'
  if (uri.match(re)) {
    return uri.replace(re, '$1' + key + '=' + value + '$2')
  } else {
    return uri + separator + key + '=' + value
  }
}

function Utf8ArrayToStr(array) {
  var out, i, len, c
  var char2, char3

  out = ''
  len = array.length
  i = 0
  while (i < len) {
    c = array[i++]
    switch (c >> 4) {
      case 0:
      case 1:
      case 2:
      case 3:
      case 4:
      case 5:
      case 6:
      case 7:
        // 0xxxxxxx
        out += String.fromCharCode(c)
        break
      case 12:
      case 13:
        // 110x xxxx   10xx xxxx
        char2 = array[i++]
        out += String.fromCharCode(((c & 0x1f) << 6) | (char2 & 0x3f))
        break
      case 14:
        // 1110 xxxx  10xx xxxx  10xx xxxx
        char2 = array[i++]
        char3 = array[i++]
        out += String.fromCharCode(
          ((c & 0x0f) << 12) | ((char2 & 0x3f) << 6) | ((char3 & 0x3f) << 0)
        )
        break
    }
  }

  return out
}
const toBase64 = function (u8) {
  return btoa(String.fromCharCode.apply(null, u8))
}

const fromBase64 = function (str) {
  return atob(str)
    .split('')
    .map(function (c) {
      return c.charCodeAt(0)
    })
}

const getIv = function () {
  const nanoid = customAlphabet('0123456789abcdefghijklmnopqrstuvwxyz', 16)
  let iv = nanoid()
  let result = []
  for (let i = 0; i <= 15; i++) {
    let code = Number(iv.charCodeAt(i)).toString(16)
    result.push(code)
  }
  result = result.join('')
  return result
}

// 把金额末尾的0去掉，但是不足两位小数的要补0
const formatAmount = function (str) {
  if (!str) return str
  let arr = str.toString().split('.')
  let res = arr[1]
  if (arr.length === 1) {
    return `${arr[0]}.00`
  }
  if (res) {
    res = res.replace(/(0+)\b/gi, '')
    if (res.length == 0) res = `${res}00`
    if (res.length == 1) res = `${res}0`
  }
  return `${arr[0]}.${res}`
}

export {
  deepCopy,
  flatten,
  flattenTree,
  normalizerPropertyOption,
  getBreadcrumb,
  isObjectEquals,
  prettyBytes,
  toDayJsFormat,
  dataURLtoBlob,
  stringTemplateFormat,
  sleep,
  updateQueryStringParameter,
  Utf8ArrayToStr,
  toBase64,
  fromBase64,
  getIv,
  formatAmount
}
