const sleep = (time) => {
  return new Promise(resolve =>
    setTimeout(() => {
      resolve()
    }, time)
  )
}

export default {
  install: (Vue) => {
    Object.defineProperties(Vue.prototype, {
      $sleep: {
        get: () => {
          return sleep
        },
      }
    })
  }
}