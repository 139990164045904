//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import attachment from '@/core/mixins/couchdb/attachment'
import EventBus from '@/core/eventbus'

export default {
  name: 'App',
  mixins: [attachment],
  data() {
    return {
      configDocuments: [
        'settings:config.cms',
        'settings:config.notice',
        'settings:config.queues',
        'settings:config.sms'
      ],
      loading: true
    }
  },
  async created() {
    // 尝试获取多语言库
    let result = await this.getConfig()
    const replicateRequired = !Array.isArray(result) || result.length === 0
    // let hasConflicts = false
    // // 如果本地库已经存在，那么检查本地库是否存在冲突
    // if (!replicateRequired) {
    //   let reloadRequired = await this.$store.dispatch(
    //     'Common/Daemon/checkConflicts',
    //     ['config', 'properties']
    //   )
    //   if (reloadRequired) {
    //     this.$swal
    //       .fire({
    //         title: '基础数据更新中',
    //         html: '请注意，服务器端的基础数据发生了变动，页面需要重新载入。',
    //         icon: 'warning',
    //         confirmButtonText: '重新载入'
    //       })
    //       .then(() => {
    //         window.location.reload()
    //       })
    //     return
    //   }
    // }

    // this.$warn(
    //   '[App:created] replateRequired',
    //   replateRequired,
    //   hasConflicts,
    //   result
    // )
    if (replicateRequired) {
      // this.$warn(
      //   '[App:created] 发现本地无配置库或存在冲突需要删除本地数据库同步'
      // )
      try {
        // eslint-disable-next-line no-debugger
        // debugger
        // // await this.$sleep(1000)
        result = await this.$store.dispatch('Common/Daemon/replicateOnce', {
          dbName: 'config'
        })
        // this.$warn('[App:created] 同步数据结果', result)
        result = await this.getConfig()
        // this.$warn('[App:created]解决冲突后再次获取数据', result)
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error('[App:created]', error)
        this.$swal
          .fire({
            icon: 'error',
            title: '无法同步基础数据',
            text: '基础数据同步失败，系统暂时无法使用，请您稍后再试',
            confirmButtonText: '再次重试'
          })
          .then(() => {
            window.location.reload()
          })
        return
      }
    }

    await Promise.all([
      this.updateFavicon(),
      this.setI18n(result[0]),
      this.setDefaultConfig(result.slice(2))
    ])
    EventBus.$on('pouchdb/config/themes:admin', this.updateFavicon)
    EventBus.$on('pouchdb/config/lang:admin', this.setI18n)
    for (let id of this.configDocuments) {
      EventBus.$on(`pouchdb/config/${id}`, this.setDefaultConfig)
    }

    // this.exceptionWatcher = this.$store.subscribe(mutation => {
    //   if (mutation.type === 'Common/Daemon/setLeader' && mutation.payload) {
    //     const title = document.title
    //     if (!title.endsWith('*')) {
    //       document.title = `${title} *`
    //     }
    //     this.$warn('Common/Daemon/setLeader', mutation.payload)
    //   }
    // })
    this.loading = false
    // this.$warn('[App:created] 基础数据加载完成', result)
  },
  beforeDestroy() {
    // this.exceptionWatcher()
    EventBus.$off('pouchdb/config/themes:admin', this.updateFavicon)
    EventBus.$off('pouchdb/config/lang:admin', this.setI18n)
    for (let id of this.configDocuments) {
      EventBus.$off(`pouchdb/config/${id}`, this.setDefaultConfig)
    }
  },
  methods: {
    getConfig() {
      return this.$store.dispatch('Common/Daemon/getDocuments', {
        dbName: 'config',
        keys: ['lang:admin', 'themes:admin'].concat(this.configDocuments),
        options: { conflicts: true, attachments: false }
      })
    },
    async updateFavicon() {
      let favicon = document.getElementById('favicon')
      let image = await this.getAttachments('images/favicon.png')
      favicon.href = image
    },
    async setI18n(doc) {
      let locale = this.$globalEnv.APP_ID ?? 'fallback'
      let messages = {}
      messages['fallback'] = doc?.['fallback'] ?? {}
      messages[locale] = doc?.[locale] ?? {}
      if (doc?.['fallback']) {
        this.$i18n.setLocaleMessage('fallback', doc?.['fallback'])
        // this.$warn('setI18n:fallback', doc?.['fallback'])
      }
      if (doc?.[locale]) {
        this.$i18n.setLocaleMessage(locale, doc?.[locale])
        // this.$warn('setI18n:fallback', locale, doc?.[locale])
      }

      let names =
        this?.$t('global.name') && this.$t('global.name') != 'global.name'
          ? [this?.$t('global.name')]
          : []

      if (this.$route.meta?.name) {
        let name = this.$route.meta.name
        name = typeof name == 'function' ? name() : name
        names.push(name)
      }
      document.title = names.length > 1 ? names.join(' -- ') : ''
    },
    async setDefaultConfig(docs) {
      if (!Array.isArray(docs)) {
        docs = [docs]
      }
      for (let doc of docs) {
        if (!doc) {
          continue
        }
        // eslint-disable-next-line no-unused-vars
        let { appId, md5, _id, _rev, _revisions, ...config } = doc
        this.$store.commit('setConfig', config)
      }
    }
  }
}
