import Global from './Global'
import Cache from './Cache'

export default {
  namespaced: true,
  modules: {
    Cache,
    Global
  }
}
