// import iView from 'iview'
import ViewUI from 'view-design'
import Vue from 'vue'
import VueRouter from 'vue-router'
import qs from 'qs'
import store from '../store'

// import web from './modules/web'
// import admin from './modules/admin'
// import manage from './modules/manage'
// import root from './modules/root'
// import personal from './modules/personal'
// import hospital from './modules/hospital'
// import project from './modules/project'
// import asset from './modules/asset'
// import provider from './modules/provider'
// import cms from './modules/cms'
// import branch from './modules/branch'

const files = require.context('./modules', false, /\.js$/)
let routes = []

files.keys().map(item => {
  // eslint-disable-next-line no-console
  const name = item.replace(/\.\/(\w+)\.js$/g, '$1')
  if (name === 'index') {
    return
  }
  let content = require(`./modules/${name}`).default
  routes = routes.concat(content)
})
// eslint-disable-next-line no-console
console.log(routes)
// import VueI18n from 'vue-i18n'
// Vue.use(VueI18n)

Vue.use(VueRouter)
Vue.use(ViewUI)

// const routes = [].concat(
//   web,
//   admin,
//   manage,
//   personal,
//   hospital,
//   project,
//   root,
//   asset,
//   provider,
//   cms,
//   branch
// )

const router = new VueRouter({
  base: process.env.CONFIGS.APP_PUBLIC_PATH,
  mode: 'history',
  routes,
  parseQuery(query) {
    return qs.parse(query)
  },
  stringifyQuery(query) {
    var result = qs.stringify(query)

    return result ? '?' + result : ''
  },
  duplicateNavigationPolicy: 'ignore'
})

router.beforeEach((to, from, next) => {
  store.commit('Common/Menu/reset')
  ViewUI.LoadingBar.start()
  Vue.nextTick(() => {
    let names =
      router.app?.$t('global.name') &&
      router.app.$t('global.name') != 'global.name'
        ? [router.app?.$t('global.name')]
        : []

    if (to?.meta?.name) {
      if (typeof to.meta.name == 'function') {
        let callback = to.meta.name
        names.push(callback())
      } else {
        names.push(to.meta.name)
      }
    }
    document.title = names.length ? names.join(' -- ') : ''
  })

  if (to.matched.some(r => r.meta.requireAuth)) {
    if (localStorage.getItem('jwtToken')) {
      next()
    } else {
      next({
        name: 'web.login',
        query: {
          redirect: to.fullPath
        }
      })
    }
  } else {
    next()
  }
})

router.afterEach(() => {
  ViewUI.LoadingBar.finish()
  window.scrollTo(0, 0)
})

export default router
