export default {
  namespaced: true,
  state: {
    routeName: null
  },
  mutations: {
    setRouteName(state, routeName) {
      state.routeName = routeName
    },
    reset(state) {
      state.routeName = null
    }
  }
}
