export default {
  localforage: {
    app: {
      name: 'app'
    },
    properties: {
      name: 'properties',
      storeName: 'properties'
    },
    'ordered-properties': {
      name: 'ordered-properties'
    },
    user: {
      name: 'user'
    }
  }
}
