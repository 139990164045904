import Property from './Property'
import Schema from './Schema'
import Organization from './Organization'
import Auth from './Auth'
import Daemon from './Daemon'
import Preset from './Preset'
import Menu from './Menu'

export default {
  namespaced: true,
  modules: {
    Auth,
    Property,
    Organization,
    Schema,
    Daemon,
    Preset,
    Menu
  }
}
