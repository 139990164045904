import axios from 'axios'
import store from '../store'
import router from '../router'

import encryptAdapter from './vendor/zdbx/encrypt'
const defaultAdapter = require('axios/lib/adapters/http')
// axios 配置
axios.defaults.timeout = 60 * 1000
axios.defaults.withCredentials = true
axios.defaults.headers.post['Content-Type'] = 'application/json'
axios.defaults.headers.put['Content-Type'] = 'application/json'
// 根据配置不同引入不同的Adapter
axios.defaults.adapter = config => {
  // eslint-disable-next-line no-console
  // console.log('axios.defaults', axios.defaults?.encryptModule ?? 'NOT FOUND')
  if (axios.defaults.encryptModule) {
    // eslint-disable-next-line no-console
    // console.warn('已经配置了加密模块', axios.defaults.encryptModule)
    return encryptAdapter(config)
  }
  return new defaultAdapter(config)
}
// http request 拦截器
axios.interceptors.request.use(
  async function (config) {
    // eslint-disable-next-line no-console
    // console.log('axios.interceptors.request', config, axios._config)
    if (config.ignoreInterceptor) {
      // config.withCredentials = false
      return config
    }
    let { url } = config
    if (url.indexOf('@/data') === 0) {
      config.url = 'static' + url.substring(6)
      config.baseURL = ''
    }
    // 判断是否存在token，如果存在的话，则每个http header都加上token
    let token = localStorage.getItem('jwtToken')
    if (token) {
      config.headers.Authorization = `${token}`
    }
    let siteId = store.state.SiteStore.id
    if (siteId && !(config.headers && config.headers['X-SITE-ID'])) {
      config.headers['X-SITE-ID'] = siteId
    }

    return config
  },
  function (err) {
    return Promise.reject(err)
  }
)

function getErrorMessage(error) {
  let message = error.response.data
  if (
    error.response.data &&
    typeof error.response.data.message === 'string' &&
    error.response.data.message !== ''
  ) {
    message = error.response.data.message
  }

  if (typeof message !== 'string') {
    message = JSON.stringify(message)
  }
  return message
}

// http response 拦截器
axios.interceptors.response.use(
  response => {
    const url = response?.config?.url || ''
    if (url.indexOf('/web/auth/refresh/') < 0) {
      store.dispatch('Common/Auth/tik')
    }

    return response
  },
  error => {
    if (error.response) {
      let body = []
      let message = ''
      const vue = window.vue
      const route = vue.$route
      switch (error.response.status) {
        case 400:
          break
        case 401: {
          let r = JSON.stringify({
            name: route.name,
            params: route.params
          })
          console.warn('太久不操作退出登录了', r) // eslint-disable-line no-console
          vue.$Notice.close('sessionTime')
          let lastLoginTime = localStorage.getItem('jwtLastLoginedAt')
          if (lastLoginTime) {
            vue.$Notice.error({
              title: '登录超时',
              desc: '您太久没有操作了，需要重新登录后再继续操作。',
              duration: vue.$store.state.default.notice.error.duration || 8,
              name: 'sessionTime'
            })
          } else {
            vue.$Notice.warning({
              title: '未登录',
              desc: '您尚未登录，请您先登录。',
              duration: vue.$store.state.default.notice.warning.duration || 8,
              name: 'sessionTime'
            })
          }

          // 返回 401 清除token信息并跳转到登录页面
          store.commit('Common/Auth/logout')
          router.replace({
            name: 'web.login',
            query: {
              _ts: +new Date()
            }
          })
          return Promise.reject(error.response)
        }
        case 403:
          vue.$Notice.error({
            title: '权限错误',
            desc: '您没有权限访问该模块，请联系系统管理员。',
            duration: vue.$store.state.default.notice.error.duration || 8
          })
          break
        case 404:
        case 406:
        case 409:
          break
        case 422:
          if (typeof error.response.data != 'object') {
            break
          }
          for (let key of Object.keys(error.response.data)) {
            const values = error.response.data[key]
            let msg = Array.isArray(values) ? values.join(',') : values
            body.push({
              field: key,
              msg
            })
          }
          error.response.data = body
          break
        case 423:
          if (typeof error.response.data === 'string') {
            vue.$Notice.error({
              title: '您操作的太快',
              desc: error.response.data,
              duration: vue.$store.state.default.notice.error.duration || 8
            })
            return
          }
          for (let key of Object.keys(error.response.data)) {
            const values = error.response.data[key]
            body.push({
              field: key,
              msg: values.join(',')
            })
          }
          error.response.data = body
          break
        case 500:
          message = getErrorMessage(error)
          vue.$Notice.error({
            title: '服务器内部错误',
            desc: message,
            duration: vue.$store.state.default.notice.error.duration || 8
          })
          break
        default:
          message = getErrorMessage(error)
          vue.$Notice.error({
            title: '出错了',
            desc: `错误代码：${error.response.status}，详情：${message}`,
            duration: vue.$store.state.default.notice.error.duration || 8
          })
          break
      }
    } else {
      // eslint-disable-next-line no-console
      console.error('无法发送请求', error)
    }
    // 返回接口返回的错误信息
    return Promise.reject(error.response)
  }
)

export default axios
