import Vue from 'vue'
import Vuex from 'vuex'

import createPersistedState from 'vuex-persistedstate'

import RoleStore from './modules/Common/RoleStore'
import SitesStore from './modules/Common/SitesStore'
import SiteStore from './modules/Common/SiteStore'
import UserStore from './modules/Common/UserStore'

// cms
import CategoriesStore from './modules/Cms/CategoriesStore'

// 通用模块
import Common from './modules/Common/'

// 个人模块
import Personal from './modules/Personal/'

// 乐华相关
import Manage from './modules/Manage/'

// 记录需要持久化的state
const dataState = createPersistedState({
  storage: window.sessionStorage,
  paths: ['default', 'UserStore.user', 'UserStore.sites']
})

Vue.use(Vuex)

import storage from '@/store/config/storage'

let store = {
  state: { default: { storage } },
  modules: {
    SiteStore,
    UserStore,
    RoleStore,
    SitesStore,
    CategoriesStore,
    Common,
    Personal,
    Manage
  },
  mutations: {
    setConfig(state, obj) {
      for (let key of Object.keys(obj)) {
        state.default[key] = obj[key]
      }
      // eslint-disable-next-line no-console
      console.log('setConfig', state)
    }
  },
  plugins: [dataState]
}
let state = new Vuex.Store(store)

export default state
