//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState } from 'vuex'
export default {
  name: 'CommonPreset',
  props: {
    name: {
      type: String,
      default: 'hospital'
    },
    routeName: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      preset: null
    }
  },

  computed: {
    ...mapState('Common/Preset', ['presets'])
  },
  mounted() {
    this.initPresets()
  },
  methods: {
    initPresets() {
      this.$store.dispatch('Common/Preset/fetch', this.routeName)
      this.preset = null
    },
    async savePresets(values) {
      this.$showEditor({
        formId:
          this.routeName.indexOf('hospital') === 0
            ? 'hospital.setting.preset.create'
            : 'project.preset.create',
        type: 'create',
        name: this.name,
        data: {
          route_name: this.routeName,
          values
        }
      })
    },
    applyPreset() {
      let preset = this.presets.find(item => item.id === this.preset)
      if (!preset) {
        return
      }
      this.$Modal.confirm({
        title: `确定要应用预置模版「${preset.name}」吗？`,
        content: `应用预置模版「${preset.name}」后将替换当前表单中所有内容，确定要应用吗？`,
        okText: '确定',
        cancelText: '取消',
        loading: true,
        onOk: () => {
          this.$emit('input', preset)
          this.$Modal.remove()
        }
      })
    }
  }
}
