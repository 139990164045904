import { flatten, getBreadcrumb } from '@/core/util'
export default {
  namespaced: true,
  state: {
    exception: null,
    organizations: sessionStorage.getItem('organizations')
      ? JSON.parse(sessionStorage.getItem('organizations'))
      : false,
    selectedOrganization: null
  },
  mutations: {
    setException(state, exception) {
      state.exception = exception
    },
    setOrganizations(state, organizations) {
      state.organizations = organizations
      sessionStorage.setItem('organizations', JSON.stringify(organizations))
    },
    setSelectedOrganization(state, selectedOrganization) {
      state.selectedOrganization = selectedOrganization
    },
    reset(state) {
      sessionStorage.removeItem('organizations')
      state.exception = null
      state.selectedOrganization = null
      state.organizations = false
    }
  },
  actions: {
    async fetch({ commit, state }, force) {
      if (state.organizations !== false && force !== true) {
        return state.organizations
      }
      let response
      try {
        response = await this._vm.$axios.get('/common/organization/')
      } catch (error) {
        const message =
          typeof error.data === 'string'
            ? error.data
            : JSON.stringify(error.data)
        commit('setException', {
          title: '无法获取组织机构信息',
          message
        })
        return
      }

      commit('setOrganizations', response.data)
      return response.data
    },
    async getOrganizations({ state, dispatch }, ids) {
      if (!state.organizations) {
        await dispatch('fetch')
      }
      let organizations = this._vm.$clone(state.organizations)
      let orgs = []
      if (!ids || ids.length == 0) {
        ids = Object.keys(organizations)
      }

      if (typeof ids === 'string') {
        ids = [ids]
      }

      let prepareData = function (item) {
        item.label = item.name
        item['可选'] = '是'
        if (item.children && item.children.length > 0) {
          item.children = item.children.map(prepareData)
        } else {
          delete item.children
        }
        return item
      }

      orgs = ids.map(id => prepareData(organizations[id]))
      return orgs
    },
    reset({ commit }) {
      commit('reset')
    }
  },
  getters: {
    flatOrganizations: state =>
      flatten(Object.values(state.organizations || {})),
    organization: state => (id, breadcrumb) => {
      if (state.organizations === false) {
        return null
      }
      let organizations = JSON.parse(JSON.stringify(state.organizations))
      let result = []
      getBreadcrumb(id, Object.values(organizations), result)
      if (result === false) {
        return null
      }
      if (breadcrumb) {
        return result
      }
      if (!result.length) {
        return null
      }
      let fullname = result.map(item => item.name).join(' / ')
      result = result.pop()
      result.fullname = fullname
      return result
    }
  }
}
