export default [
  {
    path: '/manage/:site/',
    name: 'manage.home',
    component: () => import('@/views/admin/Index.vue'),
    children: [
      /********************* 系统裁剪拾请保留以下内容 *********************/
      {
        path: 'user/',
        name: 'manage.user',
        meta: {
          name: '成员管理'
        },
        component: () => import('@/views/manage/users/Index.vue')
      },
      /********************* 系统裁剪拾请保留以上内容 *********************/
      {
        path: 'subscriber/',
        name: 'manage.subscriber',
        meta: {
          name: '会员粉丝管理'
        },
        component: () => import('@/views/manage/subscriber/Index.vue')
      },
      {
        path: 'client/',
        name: 'manage.client',
        meta: {
          name: '会员管理'
        },
        component: () => import('@/views/manage/client/Index.vue')
      },
      {
        path: 'client/:client',
        name: 'manage.client.detail',
        meta: {
          name: '会员详情'
        },
        component: () => import('@/views/manage/client/Detail.vue')
      },
      {
        path: 'complaint/',
        name: 'manage.complaint',
        meta: {
          name: 'VIP 投诉建议'
        },
        component: () => import('@/views/manage/complaint/Index.vue')
      },
      {
        path: 'detectauth/',
        name: 'manage.detectauth',
        meta: {
          name: '认证审核'
        },
        component: () => import('@/views/manage/detectauth/Index.vue')
      },
      {
        path: 'cms/campaign/',
        name: 'manage.cms.campaign',
        meta: {
          name: '活动管理'
        },
        component: () => import('@/views/manage/cms/campaign/Index.vue')
      },
      {
        path: 'cms/campaign/:id/lock',
        name: 'manage.cms.campaign.lock',
        meta: {
          name: '参与明细',
          fallbackRoute: {
            name: 'manage.cms.campaign'
          }
        },
        component: () => import('@/views/manage/cms/campaign/lock/Index.vue')
      },
      {
        path: 'card/pokeman',
        name: 'manage.card.pokeman',
        meta: {
          name: '宝可梦卡列表'
        },
        component: () => import('@/views/manage/card/pokeman/Index.vue')
      },
      {
        path: 'cms/document/',
        name: 'manage.cms.document',
        meta: {
          name: '活动管理'
        },
        component: () => import('@/views/manage/cms/document/Index.vue')
      },
      {
        path: 'record/detectauth/',
        name: 'manage.record.detectauth',
        meta: {
          name: '身份核验台账'
        },
        component: () => import('@/views/manage/record/detectauth/Index.vue')
      },
      {
        path: 'record/sync/',
        name: 'manage.record.sync',
        meta: {
          name: '积分同步'
        },
        component: () => import('@/views/manage/record/sync/Index.vue')
      },
      {
        path: 'record/bonus/',
        name: 'manage.record.bonus',
        meta: {
          name: '积分明细'
        },
        component: () => import('@/views/manage/record/bonus/Index.vue')
      },
      {
        path: 'record/member/',
        name: 'manage.record.member',
        meta: {
          name: '会员卡列表'
        },
        component: () => import('@/views/manage/record/member/Index.vue')
      },
      {
        path: 'coupon/list/',
        name: 'manage.coupon.list',
        meta: {
          name: '优惠券管理'
        },
        component: () => import('@/views/manage/coupon/list/Index.vue')
      },
      {
        path: 'coupon/rules/',
        name: 'manage.coupon.rules',
        meta: {
          name: '优惠券列表'
        },
        component: () => import('@/views/manage/coupon/rules/Index.vue')
      },
      {
        path: 'coupon/sync/',
        name: 'manage.coupon.sync',
        meta: {
          name: '优惠券同步记录'
        },
        component: () => import('@/views/manage/coupon/sync/Index.vue')
      },
      {
        path: 'coupon/record/',
        name: 'manage.coupon.record',
        meta: {
          name: '优惠券台账'
        },
        component: () => import('@/views/manage/coupon/record/Index.vue')
      },
      {
        path: 'coupon/manual/',
        name: 'manage.coupon.manual',
        meta: {
          name: '手动发放列表'
        },
        component: () => import('@/views/manage/coupon/manual/Index.vue')
      },
      {
        path: 'coupon/manual/:id/detail',
        name: 'manage.coupon.manual.detail',
        meta: {
          name: '发放明细',
          fallbackRoute: {
            name: 'manage.coupon.manual'
          }
        },
        component: () => import('@/views/manage/coupon/task/record/Index.vue')
      },
      {
        path: 'coupon/detail/',
        name: 'manage.coupon.detail',
        meta: {
          name: '优惠券台账'
        },
        component: () => import('@/views/manage/coupon/detail/Index.vue')
      },
      {
        path: 'order/ecommerce/',
        name: 'manage.order.ecommerce',
        meta: {
          name: '电商订单管理'
        },
        component: () => import('@/views/manage/order/ecommerce/Index.vue')
      },
      {
        path: 'order/offline/',
        name: 'manage.order.offline',
        meta: {
          name: '线下订单管理'
        },
        component: () => import('@/views/manage/order/offline/Index.vue')
      }
    ]
  }
]
