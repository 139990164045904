import { v4 as uuidv4 } from 'uuid'
const getUuid = withDash => {
  withDash = withDash ?? true
  return withDash ? uuidv4() : uuidv4().replaceAll('-', '')
}

export default {
  install: Vue => {
    Object.defineProperties(Vue.prototype, {
      $getUuid: {
        get: () => {
          return getUuid
        }
      }
    })
  }
}
