/**
 * Simple object check.
 * @param item
 * @returns {boolean}
 */
const isObject = item => {
  return (
    item &&
    typeof item === 'object' &&
    !Array.isArray(item) &&
    typeof item.getMonth !== 'function'
  )
}

/**
 * Deep merge two objects.
 * @param target
 * @param ...sources
 */
const mergeDeep = (target, ...sources) => {
  if (!sources.length) return target
  const source = sources.shift()

  if (isObject(target) && isObject(source)) {
    for (const key in source) {
      if (isObject(source[key])) {
        if (!target[key]) Object.assign(target, { [key]: {} })
        mergeDeep(target[key], source[key])
      } else {
        Object.assign(target, { [key]: source[key] })
      }
    }
  }

  return mergeDeep(target, ...sources)
}

export default {
  install: Vue => {
    Object.defineProperties(Vue.prototype, {
      $merge: {
        get: () => {
          return mergeDeep
        }
      }
    })
  }
}
