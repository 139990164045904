export default [
  {
    path: '/cms/:site/',
    name: 'cms.home',
    component: () => import('@/views/admin/Index.vue'),
    children: [
      {
        path: 'article',
        name: 'cms.article',
        component: () => import('@/views/cms/article/Index.vue')
      },
      {
        path: 'article/edit/:article?',
        name: 'cms.article.edit',
        meta: {
          name: '编辑系统公告',
          fallbackRoute: {
            name: 'cms.article'
          }
        },
        component: () => import('@/views/cms/article/Editor.vue')
      },
      {
        path: 'announcement',
        name: 'cms.announcement',
        component: () => import('@/views/cms/announcement/Index.vue')
      },
      {
        path: 'announcement/:article',
        name: 'cms.announcement.get',
        meta: {
          name: '阅读系统公告',
          fallbackRoute: {
            name: 'cms.announcement'
          }
        },
        component: () => import('@/views/cms/announcement/View.vue')
      }
    ]
  }
]
