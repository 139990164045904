import PS from 'perfect-scrollbar'

export default {
  name: 'scroll',
  inserted(el, binding) {
    // eslint-disable-line no-unused-vars
    if (binding.value) {
      const callback =
        typeof binding.value === 'function' ?
        binding.value :
        binding.value.callback

      el.addEventListener('scroll', callback, {
        passive: true
      })
    }

    el.style.position = 'relative'
    let options = {}

    if (binding.modifiers) {
      if (binding.modifiers.y) {
        options['suppressScrollX'] = true
      }
      if (binding.modifiers.x) {
        options['suppressScrollY'] = true
      }
    }
    el.ps = new PS(el, options)

    let ticks = 0
    const interval = setInterval(function () {
      el.ps.update()
      ticks++
      if (ticks >= 20) clearInterval(interval)
    }, 200)
  },
  unbind(el, binding) {
    if (binding.value) {
      el.removeEventListener('scroll', binding.callback, {
        passive: true
      })
    }

    el.style.position = 'static'
    el.ps.destroy()
  },
  componentUpdated(el) {
    el.ps.update()

    let ticks = 0
    const interval = setInterval(function () {
      el.ps.update()
      ticks++
      if (ticks >= 20) clearInterval(interval)
    }, 200)
  },
}