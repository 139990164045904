import dottie from '@/core/vendor/dottie'

export default function (object, path, fallbackPath) {
  // eslint-disable-next-line no-console
  // console.warn('dottie', object, path)
  let value = dottie.get(object, path)
  if (Array.isArray(value)) {
    value = value[0]
  }
  if (!value) {
    value = dottie.get(object, fallbackPath)
  }
  return value
}
