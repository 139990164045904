export default [
  {
    path: '/root/:site/',
    name: 'root.home',
    component: () => import('@/views/admin/Index.vue'),
    children: [
      {
        path: 'cache/',
        name: 'root.cache',
        component: () => import('@/views/root/cache/Index.vue')
      },
      {
        path: 'exception/job',
        name: 'root.exception.job',
        component: () => import('@/views/root/exception/job/Index.vue')
      },
      {
        path: 'schema/',
        name: 'root.schema',
        component: () => import('@/views/root/schema/Index.vue')
      },
      // ------- 以下是新增页面 ---------
      {
        path: 'whitelist/',
        name: 'root.whitelist.list',
        meta: {
          name: '白名单'
        },
        component: () => import('@/views/root/whitelist/Index.vue')
      }
    ]
  }
]
