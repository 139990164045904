export default [
  {
    path: '/error/404',
    name: 'error.404',
    component: () => import('@/views/error/404.vue')
  },
  {
    path: '*',
    name: 'error.404.redirect',
    component: () => import('@/views/error/redirect/404.vue')
  }
]
