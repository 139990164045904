var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "app" } },
    [
      !_vm.loading
        ? _c("router-view")
        : _c("div", { staticClass: "app-spinner-container" }, [
            _c("div", { staticClass: "spinner" }, [
              _c("div", { staticClass: "rect1" }),
              _vm._v(" "),
              _c("div", { staticClass: "rect2" }),
              _vm._v(" "),
              _c("div", { staticClass: "rect3" }),
              _vm._v(" "),
              _c("div", { staticClass: "rect4" }),
              _vm._v(" "),
              _c("div", { staticClass: "rect5" })
            ])
          ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }