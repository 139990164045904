export default [
  {
    path: '/personal/:site/',
    name: 'personal.home',
    component: () => import('@/views/personal/Index.vue'),
    children: [
      {
        path: 'dashboard',
        name: 'personal.dashboard',
        component: () => import('@/views/personal/dashboard/Index.vue')
      },
      {
        path: 'notification',
        name: 'personal.notification',
        component: () => import('@/views/personal/notification/Index.vue')
      }
    ]
  }
]
