export default {
  namespaced: true,
  state: {
    user: false,
    sites: false
  },
  mutations: {
    setUser(state, user) {
      state.user = user
      this._vm.$storage['user'].setItem('currentUser', state.user)
    },
    appendSite(state, site) {
      state.sites = Array.isArray(state.sites)
        ? state.sites.concat([site])
        : [site]
      this._vm.$storage['user'].setItem('sites', state.sites)
    },
    removeSite(state, id) {
      let index = state.sites.findIndex(site => site.id === id)
      if (index >= 0) {
        state.sites.splice(index, 1)
      }
      this._vm.$storage['user'].setItem('sites', state.sites)
    },
    updateSite(state, site) {
      let index = state.sites.findIndex(item => item.id === site.id)
      if (index >= 0) {
        state.sites[index] = site
      }
      this._vm.$storage['user'].setItem('sites', state.sites)
    },
    updateSites(state, sites) {
      state.sites = sites
      this._vm.$storage['user'].setItem('sites', state.sites)
    },
    fetch(state, payload) {
      state.user = payload.user
      state.sites = payload.sites
      this._vm.$storage['user'].setItem('currentUser', state.user)
      this._vm.$storage['user'].setItem('sites', state.sites)
    },
    name(state, name) {
      if (state.user && state.user.name) {
        state.user.name = name
        this._vm.$storage['user'].setItem('currentUser', state.user)
      }
    },
    mobile(state, mobile) {
      if (state.user && (state.user.mobile || state.user.mobile == null)) {
        state.user.mobile = mobile
        this._vm.$storage['user'].setItem('currentUser', state.user)
      }
    },
    email(state, email) {
      if (state.user && (state.user.email || state.user.email == null)) {
        state.user.email = email
        state.user.validated_at = null
        this._vm.$storage['user'].setItem('currentUser', state.user)
      }
    },
    validated(state) {
      if (state.user && state.user.email) {
        state.user.validated_at = new Date()
        this._vm.$storage['user'].setItem('currentUser', state.user)
      }
    },
    avatar(state, url) {
      if (state.user) {
        state.user.avatar_url = url
        this._vm.$storage['user'].setItem('currentUser', state.user)
      }
    },
    reset(state) {
      state.user = null
      state.sites = null
      try {
        this._vm.$storage['user'].removeItem('sites')
        this._vm.$storage['user'].removeItem('currentUser')
      } catch (err) {
        // eslint-disable-next-line no-console
        console.warn('存储未初始化')
      }
    },
    touch(state) {
      state.sites = state.sites.map(site => {
        site._ts = +new Date()
        return site
      })
    }
  },
  actions: {
    async initialize({ state }, force) {
      if (!state.user || force) {
        state.user = await this._vm.$storage['user'].getItem('currentUser')
        // eslint-disable-next-line no-console
        // console.warn('[UserStore::initialize] 当前用户信息', state.user)
      }
      if (!state.sites || force) {
        state.sites = await this._vm.$storage['user'].getItem('sites')
      }
    },
    appendSite({ commit }, site) {
      commit('appendSite', site)
    },
    removeSite({ commit }, site) {
      commit('removeSite', site)
    },
    updateSite({ commit, rootState }, site) {
      commit('updateSite', site)
      if (rootState.SiteStore.id === site.id) {
        this.dispatch('SiteStore/setSiteById', site.id)
      }
    },
    async getSites() {
      let res = await this._vm.$axios.get('/web/user/site/')
      // eslint-disable-next-line no-console
      console.log('getSites', res.data)
      let sites = res.data
      let items = sites
        .filter(site => site.type == 'project')
        .reduce((acc, cur) => {
          acc[cur.parent_id] = acc[cur.parent_id]
            ? acc[cur.parent_id].concat([cur.project_template_id])
            : [cur.project_template_id]
          return acc
        }, {})
      // eslint-disable-next-line no-console
      // console.warn('-------------------items------------', items)
      let templates = {}
      for (let hospitalId of Object.keys(items)) {
        let pos = sites.findIndex(site => site.id === hospitalId)
        if (pos < 0) {
          continue
        }
        let templateIds = [...new Set(items[hospitalId])]

        sites[pos].templates = await this._vm.$getProperties(
          templateIds,
          sites[pos],
          'hospital'
        )
        templates[hospitalId] = sites[pos].templates

        // // eslint-disable-next-line no-console
        // console.warn(
        //   '-------------------',
        //   hospitalId,
        //   templateIds,
        //   sites[pos].templates
        // )
      }
      sites = sites.map(site => {
        if (site.type === 'project') {
          site.template =
            templates[site.parent_id]?.[site.project_template_id] ?? null
        }
        return site
      })
      // eslint-disable-next-line no-console
      console.warn('-------------------', sites)
      return sites
    },
    async fetchSites({ commit, rootState, dispatch }) {
      let sites = await dispatch('getSites')
      // this._vm.$warn('fetch sites', sites)
      commit('updateSites', sites)
      const currentSiteId = rootState.SiteStore?.id ?? null
      // eslint-disable-next-line no-console
      // console.log('当前站点', currentSiteId, rootState.SiteStore)
      if (currentSiteId) {
        const site = sites.find(item => item.id === currentSiteId)
        if (site) {
          // eslint-disable-next-line no-console
          // console.warn('更新当前站点信息')
          commit('SiteStore/setSite', site, {
            root: true
          })
        }
      }
    },
    async fetch({ commit, dispatch }) {
      // eslint-disable-next-line no-console
      console.warn('UserStore/fetch')
      let [userRes, sites] = await Promise.all([
        this._vm.$axios.get('/web/user/info/'),
        dispatch('getSites')
      ])

      // eslint-disable-next-line no-console
      console.warn('UserStore/fetch', userRes)
      commit('fetch', {
        user: userRes.data,
        sites
      })
    },
    name({ commit }, name) {
      commit('name', name)
    },
    mobile({ commit }, mobile) {
      commit('mobile', mobile)
    },
    email({ commit }, email) {
      commit('email', email)
    },
    validated({ commit }) {
      commit('validated')
    },
    avatar({ commit }, url) {
      commit('avatar', url)
    },
    touch({ commit }) {
      commit('touch')
    }
  },
  getters: {
    info: state => {
      return state.user
    },
    id: state => {
      if (state.user && state.user.id) {
        return state.user.id
      }
      return null
    },
    avatar: (state, getters) => (getters.info ? getters.info.avatar_url : null),
    faceImgUrl: (state, getters) =>
      getters.info && getters.info.access_face_url
        ? getters.info.access_face_url
        : null,
    sites: state => state.sites || [],
    site: state => id => {
      let sites = state.sites || []
      return sites.find(item => item.id === id)
    },
    user: state => state.user,
    favoriteContainers: state => {
      if (!Array.isArray(state.user?.favorite?.containers)) {
        return []
      }
      return state.user.favorite.containers
    }
  }
}
