export default {
  namespaced: true,
  state: {
    notifications: null,
    mandatories: null,
    exception: null,
    total: null
  },
  mutations: {
    setNotifications(state, collection) {
      state.notifications = collection.data
      state.mandatories = (collection.data ?? []).filter(
        notification =>
          notification?.template?.attributes?.dm?.type === 'mandatory'
      )
    },
    setTotal(state, total) {
      state.total = total
    },
    setException(state, exception) {
      state.exception = exception
    },
    reset(state) {
      state.total = null
      state.mandatories = null
      state.exception = null
      state.notifications = null
    }
  },
  actions: {
    async fetch({ commit }) {
      let response
      try {
        response = await this._vm.$axios.get('/personal/notification/unread/', {
          pageSize: 100
        })
      } catch (error) {
        const message =
          typeof error.data === 'string'
            ? error.data
            : JSON.stringify(error.data)
        commit('setException', {
          title: '无法获取本人未读通知列表查询的信息',
          message
        })
        return
      }
      commit('setNotifications', response.data)
      commit('setTotal', response.data.total)
    },
    async markAsRead({ state, commit }, notification) {
      try {
        await this._vm.$axios.put(`/personal/notification/${notification.id}`)
        state.total = state.total >= 1 ? state.total - 1 : 0
        let pos = state.mandatories.findIndex(o => o.id === notification.id)
        if (pos >= 0) {
          state.mandatories.splice(pos, 1)
        }
      } catch (error) {
        const message =
          typeof error.data === 'string'
            ? error.data
            : JSON.stringify(error.data)
        commit('setException', {
          title: '无法将消息标志为已读',
          message
        })
        return
      }
    },
    reset({ commit }) {
      commit('reset')
    }
  }
}
