//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Preset from '@/components/Common/Preset/Index'
import components from '@/core/mixins/components'
import EventBus from '@/core/eventbus'
import ability from '@/core/mixins/ability'
export default {
  name: 'CommonPropertyUtilEditor',
  components: { Preset },
  mixins: [components, ability],
  data() {
    return {
      incrementId: 1,
      expanded: {},
      icon: null,
      title: '',
      description: '',
      payload: null,
      modalShow: false,
      initializing: true,
      processing: false,
      settings: null,
      currentStep: 0,
      currentStepStatus: 'wait',
      form: {},
      properties: {},
      propertyComponents: null,
      watchingConfigDocumentIds: []
    }
  },
  computed: {
    groupHasError() {
      return group => {
        let propertyIds = this.settings.fields
          .filter(f => f._meta?.collapse?.group == group)
          .map(field => field.id)
        let hasError = false
        propertyIds.map(id => {
          hasError = hasError || this.$refs?.[id]?.[0]?.errors.has(id)
        })
        return hasError
      }
    },
    fieldOfCurrentStep() {
      return field => {
        let step = (this.settings?.meta?.wizard?.steps ?? [])?.[
          this.currentStep
        ]
        // eslint-disable-next-line no-console
        // console.log('fieldOfCurrentStep', step, field)
        if (!step) {
          return true
        }
        return field?._meta?.wizard?.step === step?.id
      }
    },
    isDisabledButton() {
      return button => {
        let callback = button.disabled ?? null
        if (callback) {
          try {
            let func =
              typeof callback === 'string' ? new Function(callback) : callback
            func = func.bind(this)
            let ret = func()
            // eslint-disable-next-line no-console
            // console.warn('isDisabledButton', button, func, ret)
            return ret
          } catch (err) {
            // eslint-disable-next-line no-console
            console.warn('初始化按钮禁用函数失败', err)
          }
        }
        return false
      }
    }
  },
  beforeDestroy() {
    for (let id of this.watchingConfigDocumentIds) {
      EventBus.$off(id, this._updateFormByDoc)
    }
    this.watchingConfigDocumentIds = []
  },
  methods: {
    async show(payload) {
      payload.type = payload.type ?? 'create'
      this.payload = payload
      // 根据表单ID（或者路由ID）获取配置信息
      await this.getSettings()
      if (this.settings?.meta?.collapse) {
        for (let group of this.settings.meta.collapse.groups) {
          this.$set(this.expanded, group.id, group.expanded ?? false)
          // this.$warn('设置默认打开', group)
        }
        // this.$warn('设置默认打开', this.expanded)
      }
      this.modalShow = true
    },
    async _updateFormByDoc(doc) {
      if (!doc.fields) {
        return
      }
      // this.$warn('_updateFormByDoc', doc, doc.fields)
      this.initializing = true

      let AsyncFunction = new Function(
        'return Object.getPrototypeOf(async function(){}).constructor'
      )()
      this.settings = doc
      let callback = doc.meta?.preFieldInitialize ?? null
      if (callback) {
        try {
          let func =
            typeof callback === 'string'
              ? new AsyncFunction(callback)
              : callback
          func = func.bind(this)

          doc.fields = await func()
        } catch (err) {
          // eslint-disable-next-line no-console
          console.warn('执行表单初始化函数preFieldInitialize失败', err)
        }
      }
      let promises = doc.fields.reduce((acc, cur) => {
        let site = cur._meta?.site ?? null
        acc.push(this.$getProperties([cur.id], site))
        return acc
      }, [])

      let results = await Promise.all(promises)
      let properties = Object.assign(...results)
      // eslint-disable-next-line no-console
      // console.log('Form Editor getSettings', properties, results)
      for (let i = 0; i < doc.fields.length; i++) {
        const id = doc.fields[i].id
        if (!properties[id]) {
          continue
        }
        let field = this.$merge(properties[id], doc.fields[i])
        doc.fields[i] = field
      }

      callback = doc.meta?.afterSettingInitialized ?? null
      if (callback) {
        try {
          let func =
            typeof callback === 'string'
              ? new AsyncFunction('doc', callback)
              : callback
          func = func.bind(this)

          doc = await func(doc)
        } catch (err) {
          // eslint-disable-next-line no-console
          console.warn('执行表单初始化函数afterSettingInitialized失败', err)
        }
      }
      // this.$warn('合并后的属性', doc.fields)
      this.settings = doc

      await this.initResource()
      await this.initializeListeners()
      await this.initializeForm()

      callback = doc.meta?.afterFormInitialized ?? null
      if (callback) {
        try {
          let func =
            typeof callback === 'string'
              ? new AsyncFunction('doc', callback)
              : callback
          func = func.bind(this)

          doc = await func(doc)
        } catch (err) {
          // eslint-disable-next-line no-console
          console.warn('执行表单初始化函数afterFormInitialized失败', err)
        }
      }
      this.initializing = false
    },
    async getSettings() {
      let routeName = this.payload.formId ?? this.$route.name
      let pathes = routeName.split('.')
      const docId = `form:${pathes.join('.')}`
      const eventId = `pouchdb/config/${docId}`
      this.watchingConfigDocumentIds.push(eventId)
      EventBus.$on(eventId, this._updateFormByDoc)
      let settings = await this.$store.dispatch(
        'Common/Daemon/getDocumentById',
        {
          dbName: 'config',
          key: docId
        }
      )
      if (!settings) {
        this.$error('无法找到表单信息', docId)
        return
      }
      this._updateFormByDoc(settings)
    },
    async initResource() {
      let AsyncFunction = new Function(
        'return Object.getPrototypeOf(async function(){}).constructor'
      )()
      let getResource = async name => {
        if (!this.payload?.type) {
          return null
        }
        let result =
          this.settings?.meta?.form?.[this.payload.type]?.[name] || null
        if (result) {
          return result
        }
        let callback =
          this.settings?.meta?.form?.[this.payload.type]?.[`${name}Callback`] ||
          null
        if (callback) {
          try {
            let func =
              typeof callback === 'string'
                ? new AsyncFunction(callback)
                : callback
            func = func.bind(this)
            return await func()
          } catch (e) {
            // eslint-disable-next-line no-console
            console.warn('初始化资源失败', e)
            return null
          }
        }
        return null
      }
      this.icon = await getResource('icon')
      this.title = await getResource('title')
      this.description = await getResource('description')
    },
    hide() {
      this.modalShow = false
      this.processing = false
      this.$nextTick(() => {
        this.payload = null
      })
    },
    onShown() {},
    onHidden() {},
    initializeListeners() {
      let AsyncFunction = new Function(
        'return Object.getPrototypeOf(async function(){}).constructor'
      )()
      for (let i = 0; i < (this.settings?.fields ?? []).length; i++) {
        let field = this.settings.fields[i]
        let listeners = field.listeners ?? null
        if (!listeners) {
          continue
        }
        for (let key of Object.keys(listeners)) {
          let fn = listeners[key] ?? null
          if (!fn) {
            continue
          }
          fn = new AsyncFunction('event', fn)
          fn = fn.bind(this)
          listeners[key] = fn
        }
        this.settings.fields[i].listeners = listeners
      }
    },
    async initializeForm() {
      let AsyncFunction = new Function(
        'return Object.getPrototypeOf(async function(){}).constructor'
      )()
      let fields = []
      fields = this.settings?.fields ?? []
      let { properties, propertyComponents, form } =
        this.getFormByComponents(fields)

      this.$set(this, 'properties', properties)
      let callback = this.settings?.meta?.preFormInitialize ?? null
      if (callback) {
        let func =
          typeof callback === 'string' ? new AsyncFunction(callback) : callback
        func = func.bind(this)
        let obj = await func()
        form = Object.assign(form, obj)
      } else {
        form = Object.assign(form, this.payload?.data ?? {})
      }
      this.$set(this, 'form', form)
      this.$set(this, 'propertyComponents', propertyComponents)
    },
    async validateForm() {
      return new Promise((resolve, reject) => {
        let promises = [this.$validator.validateAll()]
        for (let propertyId of Object.keys(this.properties)) {
          if (this.$refs?.[propertyId]?.[0]) {
            promises.push(this.$refs[propertyId][0].$validator.validateAll())
          }
        }
        Promise.all(promises)
          .then(results => {
            resolve(!results.includes(false))
          })
          .catch(err => {
            reject(err)
          })
      })
    },

    toggleGroup(group) {
      this.$set(this.expanded, group, !this.expanded[group])
    },
    async onSubmit(e) {
      e.preventDefault()
      // const AsyncFunction = Object.getPrototypeOf(async function () {})
      //   .constructor
      this.processing = true

      const validated = await this.validateForm()
      // eslint-disable-next-line no-console
      console.log('验证结果', validated)
      if (!validated) {
        this.processing = false
        return
      }
      let form = null
      let preSubmitHook = this.settings?.meta?.preSubmit ?? null
      if (preSubmitHook) {
        try {
          if (typeof preSubmitHook === 'string') {
            let func = new Function(preSubmitHook)
            func = func.bind(this)
            form = func()
          } else {
            form = preSubmitHook.bind(this)()
          }
        } catch (e) {
          // eslint-disable-next-line no-console
          console.error(e)
          this.processing = false
          return
        }
      }
      let submitHook =
        this.settings?.meta?.[`${this.payload.type}Callback`] ?? null
      if (submitHook) {
        // this.$warn('提交的函数', submitHook)
        let func
        if (typeof submitHook !== 'string') {
          func = submitHook
          // .toString()
          // func = func.slice(func.indexOf('{') + 1, func.lastIndexOf('}'))
        } else {
          func = submitHook
          func = new Function('_form', func)
        }

        func = func.bind(this)

        // this.$warn('submitHook', func)
        let f = func(form)

        try {
          if (typeof Promise.resolve(f) === 'object') {
            f.finally(() => {
              this.processing = false
            })
          }
        } catch (e) {
          // eslint-disable-next-line no-console
          // console.warn('not a promise', e)
        }

        // this.$warn('submitHook', '执行完成')
        return
      }

      if (this.payload.submit) {
        this.payload.submit({
          original: this.payload.data,
          data: form,
          success: this.success,
          fail: this.fail,
          complete: this.complete
        })
      }
    },
    success() {
      this.$swal.fire({
        title: `${this.title || '新增'}成功`,
        icon: 'success',
        confirmButtonText: '知道了'
      })
      this.hide()
    },
    fail(error) {
      // eslint-disable-next-line no-console
      console.error(error, error.status)
      if (error.status === 400) {
        if (this.$listeners['fail']) {
          // 如果提供执行
          this.$emit('fail', {
            title: this.title,
            form: this.form
          })
        } else {
          this.$swal.fire({
            title: `${this.title || '新增'}失败`,
            showCloseButton: true,
            html:
              typeof error.data === 'object'
                ? JSON.stringify(error.data)
                : error.data,
            icon: 'error',
            confirmButtonText: '知道了'
          })
        }
        this.processing = false
        return
      }
      if (error.status === 422 || error.status === 423) {
        let errors = error.data ?? []
        for (let error of errors) {
          const key = error?.field

          if (!key) {
            continue
          }
          if (this.$refs?.[key]?.[0]) {
            this.$refs[key][0].errors.add(error)
          }
        }
        this.processing = false
        return
      }

      this.$Notice.error({
        title: '出错了',
        desc: `${this.title || '新增'}失败`
      })
      this.processing = false
    },
    complete() {
      this.hide()
    },
    getValue() {
      return this.$clone(this.form)
    },
    setValue(value) {
      this.$set(this, 'form', value)
    },
    getComponentByPropertyId(id) {
      return this.$refs[id]?.[0] ?? null
    },
    async doButtonCallback(button) {
      let AsyncFunction = new Function(
        'return Object.getPrototypeOf(async function(){}).constructor'
      )()
      if (button?.callback) {
        let callback = button.callback
        try {
          let func =
            typeof callback === 'string'
              ? new AsyncFunction(callback)
              : callback
          func = func.bind(this)

          await func()
        } catch (err) {
          // eslint-disable-next-line no-console
          console.warn('执行按钮回调函数doButtonCallback失败', err)
        }
      }
    },
    goPreviousStep() {
      this.currentStep--
      this.currentStepStatus = 'wait'
    },
    async goNextStep(e) {
      if (this.currentStep >= this.settings.meta.wizard.steps.length - 1) {
        this.onSubmit(e)
        return
      }
      let promises = [this.$validator.validateAll()]
      for (let propertyId of Object.keys(this.properties)) {
        if (this.$refs?.[propertyId]?.[0]) {
          promises.push(this.$refs[propertyId][0].$validator.validateAll())
        }
      }
      let results = await Promise.all(promises)
      const validated = !results.includes(false)
      // eslint-disable-next-line no-console
      // console.log('验证结果', results, validated)
      if (!validated) {
        this.currentStepStatus = 'error'
        return false
      }
      this.currentStep++
      this.currentStepStatus = 'wait'
    },
    async applyPreset(preset) {
      let values = this.$clone(preset.values ?? {})
      let AsyncFunction = new Function(
        'return Object.getPrototypeOf(async function(){}).constructor'
      )()
      let callback = this.settings?.meta?.preset?.applyCallback ?? null
      if (callback) {
        try {
          let func =
            typeof callback === 'string'
              ? new AsyncFunction('values', callback)
              : callback
          func = func.bind(this)
          values = await func(values)
        } catch (e) {
          // eslint-disable-next-line no-console
          console.error('applyPreset', e)
        }
      }
      // this.$warn('apply preset', callback, values)
      this.$set(this, 'form', values)
      this.incrementId++
      this.$Notice.success({
        title: `已成功使用预置模版${preset.name}`
      })
    },
    async savePresets() {
      let values = this.$clone(this.form ?? {})
      let AsyncFunction = new Function(
        'return Object.getPrototypeOf(async function(){}).constructor'
      )()
      let callback = this.settings?.meta?.preset?.saveCallback ?? null
      if (callback) {
        try {
          let func =
            typeof callback === 'string'
              ? new AsyncFunction('values', callback)
              : callback
          func = func.bind(this)
          values = await func(values)
        } catch (e) {
          // eslint-disable-next-line no-console
          console.error('savePresets', e)
        }
      }
      // this.$warn('savePresets', callback, values)
      this.$refs.preset.savePresets(values)
    }
  }
}
