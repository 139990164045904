import { isObjectEquals } from '@/core/util'

export default {
  install: Vue => {
    Object.defineProperties(Vue.prototype, {
      $isObjectEquals: {
        get: () => {
          return isObjectEquals
        }
      }
    })
  }
}
