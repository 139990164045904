import PouchDB from 'pouchdb'
import PouchDBFind from 'pouchdb-find'
PouchDB.plugin(PouchDBFind)
import store from '../store'
import { Modal } from 'view-design'
import Swal from 'sweetalert2'

const db = new PouchDB('properties')
let cachedNames = {}
let cachedIds = {}

const conflictsConfirmed = async () => {
  Swal.fire({
    title: '基础数据需要同步',
    html: '请注意，服务器端的基础数据发生了变动，需要重新同步基础数据，整个过程大约需要1-10分钟，请您耐心等待。',
    icon: 'warning',
    confirmButtonText: '立即同步',
    showLoaderOnConfirm: true,
    preConfirm: async () => {
      await store.dispatch('Common/Daemon/destroy')

      return store.dispatch('Common/Daemon/replicateOnce', {
        dbName: 'properties'
      })
    },
    allowOutsideClick: () => !Swal.isLoading()
  })
    .then(result => {
      if (result.isConfirmed) {
        Swal.fire({
          icon: 'success',
          title: '基础数据同步完成',
          html: '您的基础数据已经更新成功，点击确定后可以继续使用',
          confirmButtonText: '确定'
        }).then(() => {
          window.location.reload()
        })
      } else {
        window.location.reload()
      }
    })
    .finally(() => {
      Swal.close()
    })
}

const getProperties = async (keys, site, scope) => {
  site = site ? site : store.state.SiteStore?.site ?? null
  if (!site) {
    return null
  }
  // 当前在项目下
  let hospitalId = null
  let projectId = null
  if (site.parent_id) {
    hospitalId = site.parent_id
    projectId = site.id
  } else {
    hospitalId = site.id
  }
  if (scope == '医院' || scope == 'hospital') {
    projectId = null
  }
  keys = keys.reduce((acc, cur) => {
    acc.push(`main:${cur}`)
    hospitalId ? acc.push(`${hospitalId}:${cur}`) : ''
    projectId ? acc.push(`${projectId}:${cur}`) : ''
    return acc
  }, [])

  // eslint-disable-next-line no-console
  // console.log('需要查询的属性有', keys)
  try {
    var result = await db.allDocs({
      conflicts: true,
      include_docs: true,
      keys
    })
    let documents = []
    if (Array.isArray(result?.rows)) {
      documents = result.rows
        .filter(row => row && row.doc && !row.error)
        .map(row => row.doc)
    }
    // eslint-disable-next-line no-console
    // console.log('hasConflicts', documents)
    let hasConflicts =
      documents.filter(row => row._conflicts && row._conflicts.length > 0)
        .length > 0
    if (hasConflicts) {
      // eslint-disable-next-line no-console
      console.warn(
        '存在冲突的文档',
        documents.filter(row => row._conflicts && row._conflicts.length > 0)
      )
      conflictsConfirmed()
    }
    return documents
      .filter(doc => doc)
      .reduce((acc, cur) => {
        let property = acc[cur.property_id]

        if (property) {
          // 如果已经是项目的属性，那么什么都不做
          if (property.site_id == projectId) {
            return acc
          }

          if (cur.property_id === projectId) {
            acc[cur.property_id] = cur
            return acc
          }

          // 如果保存的属性是全局属性
          if (property.site_id == 'main') {
            acc[cur.property_id] = cur
            return acc
          }
        }
        acc[cur.property_id] = cur
        return acc
      }, {})
  } catch (err) {
    // eslint-disable-next-line no-console
    console.error(err)
  }
  return null
}

const queryProperties = args => {
  return new Promise((resolve, reject) => {
    // eslint-disable-next-line no-console
    // console.log(db, args)
    db.find(args)
      .then(result => {
        resolve(result)
      })
      .catch(err => {
        reject(err)
      })
  })
}

const getOrderedProperties = async () => {
  let site = store.state.SiteStore?.site ?? null
  if (!site) {
    return null
  }
  const siteIds =
    site.type == 'project'
      ? ['main', site.id, site.parent_id]
      : ['main', site.id]
  try {
    let response = await queryProperties({
      selector: {
        site_id: {
          $in: siteIds
        },
        priority: {
          $gt: 0
        }
      },
      fields: ['name', 'property_id'],
      sort: [
        {
          priority: 'desc'
        }
      ]
    })
    cachedNames[site.id] = response.docs.map(doc => doc.name)
    cachedIds[site.id] = response.docs.reduce((acc, cur) => {
      if (acc.includes(cur.property_id)) {
        return acc
      }
      acc.push(cur.property_id)
      return acc
    }, [])
    let result = { names: cachedNames[site.id], ids: cachedIds[site.id] }
    store.commit('Common/Property/Cache/setNames', result.names)
    store.commit('Common/Property/Cache/setIds', result.ids)
    return result
  } catch (err) {
    let msg = err.message
    let closing = msg.indexOf('The database connection is closing')
    // eslint-disable-next-line no-console
    console.error(msg, closing)
    if (closing > 0) {
      Modal.confirm({
        title: '无法打开属性数据库',
        content:
          '您可能清除了浏览器缓存，为了保证系统正常运行，请您先重新启动浏览器后再次尝试。',
        closable: false,
        'footer-hide': true,
        okText: '确定',
        onOk: () => {
          window.location.href = '/'
        },
        onCancel: () => {
          window.location.href = '/'
        }
      })
    }
    return { names: [], ids: [] }
  }
}

const getOrderedPropertyNames = async () => {
  let site = store.state.SiteStore?.site ?? null
  if (!site) {
    return null
  }
  if (!cachedNames[site.id]) {
    await getOrderedProperties()
  }
  return cachedNames?.[site.id] ?? []
}

const getOrderedPropertyIds = async () => {
  let site = store.state.SiteStore?.site ?? null
  if (!site) {
    return null
  }
  if (!cachedIds[site.id]) {
    await getOrderedProperties()
  }
  return cachedIds?.[site.id] ?? []
}

export default {
  install: Vue => {
    Object.defineProperties(Vue.prototype, {
      $getProperties: {
        get: () => {
          return getProperties
        }
      },
      $queryProperties: {
        get: () => {
          return queryProperties
        }
      },
      $getOrderedPropertyNames: {
        get: () => {
          return getOrderedPropertyNames
        }
      },
      $getOrderedPropertyIds: {
        get: () => {
          return getOrderedPropertyIds
        }
      },
      $getOrderedProperties: {
        get: () => {
          return getOrderedProperties
        }
      }
    })
  }
}
