// X-ZA 用于存储加密后的对称密钥
// X-ZB 用于存储部分私钥
// X-ZC 用于存储签名
// X-ZD 用于存储IV

const encrypt = (transformer, request, key, iv) => {
  let headers = {}
  let body = ''
  let payload = JSON.stringify(request)

  // 一律采用SM4 CBC模式加密
  body = transformer.encrypt(payload, key, iv, false)
  let encryptedKey = transformer.sm2_encrypt(key, false)
  headers['X-ZA'] = encryptedKey

  return { headers, body }
}

const decrypt = (transformer, headers, body) => {
  if (!transformer) {
    // eslint-disable-next-line no-console
    // console.error('[encrypt] 未配置解密模块')
    throw new Error('未配置解密模块')
  }
  let encryptedKey = headers['x-za']
  let partialKey = headers['x-zb']
  let digest = headers['x-zc']
  let iv = headers['x-zd']
  let sign = transformer.sign_hex(body, false)

  digest = transformer.sm2_decrypt(digest, partialKey, false)
  if (sign != digest) {
    // eslint-disable-next-line no-console
    console.log('签名值', sign, digest)
    throw new Error('签名验证失败')
  }

  let key = transformer.sm2_decrypt(encryptedKey, partialKey, false)
  body = transformer.decrypt(body, key, iv, false)
  // eslint-disable-next-line no-console
  // console.warn('结果', key, iv, body)
  return body
}
export { encrypt, decrypt }
