import PouchDB from 'pouchdb'
import axios from 'axios'
import { encrypt, decrypt } from './utility'
import { getIv } from '@/core/util'
export default (url, opts) => {
  let jwtToken = localStorage.getItem('jwtToken') ?? null

  if (jwtToken && opts.headers) {
    opts.headers.set('Authorization', jwtToken)
  }

  // 如果没有配置加密模块
  if (!axios.defaults.encryptModule) {
    return PouchDB.fetch(url, opts)
  }
  let { transformer } = axios.defaults.encryptModule ?? {}

  // eslint-disable-next-line no-console
  // console.log('[PouchDB fetch]', url, opts)
  const urlObject = new URL(url)
  let headers = {}
  for (var pair of opts.headers.entries()) {
    headers[pair[0]] = pair[1]
  }
  let request = {
    baseUrl: `${urlObject.protocol}//${urlObject.hostname}${
      urlObject.port ? `:${urlObject.port}` : ''
    }`,
    path: `${urlObject.pathname}${urlObject.search ?? ''}`,
    method: (opts.method ?? 'get').toLowerCase(),
    headers: headers,
    body: opts.body ?? null
  }

  // eslint-disable-next-line no-console
  // console.warn('[PouchDB fetch] request', request)
  const iv = getIv()
  const key = getIv()
  let payload = encrypt(transformer, request, key, iv)
  // eslint-disable-next-line no-console
  // console.warn('[PouchDB fetch] payload', payload)
  opts.body = payload.body
  opts.headers = new Headers()
  for (let key of Object.keys(payload.headers)) {
    opts.headers.append(key, payload.headers[key])
  }
  opts.headers.append('X-ZD', iv)
  let sign = transformer.sign_hex(payload.body, iv, false)
  opts.headers.append('X-ZC', transformer.sm2_encrypt(sign, false))
  opts.method = 'POST'
  url = `${axios.defaults.baseURL}web/gateway`

  return new Promise((resolve, reject) => {
    PouchDB.fetch(url, opts)
      .then(response => {
        let headers = response.headers
        if (!headers.get('X-ZD')) {
          resolve(response)
          return
        }
        const iv = headers.get('X-ZC')
        const contentType = headers.get('Content-Type')
        // eslint-disable-next-line no-console
        // console.warn('[PouchDB fetch] url', algorithm, iv, url, contentType)
        if (contentType.match(/image\//)) {
          window.vue.$warn('找到图片', opts)
          resolve(response)
          return
        }
        response
          .clone()
          .text()
          .then(body => {
            // // eslint-disable-next-line no-console
            // console.warn(
            //   '[PouchDB fetch] response',
            //   response.url,
            //   body,
            //   response.headers.get('Content-Type')
            // )
            if (!body) {
              resolve(response)
              return
            }
            try {
              let headers = {}
              for (let key of response.headers.keys()) {
                headers[key] = response.headers.get(key)
              }
              // eslint-disable-next-line no-console
              // console.log('头字段信息', headers)
              body = decrypt(transformer, headers, body, iv)
            } catch (e) {
              reject(e)
            }
            let res = new Response(new Blob([body]), {
              headers: response.headers,
              status: response.status,
              statusText: response.statusText,
              url: response.url
            })
            resolve(res)
          })
          .catch(error => {
            // eslint-disable-next-line no-console
            // console.error('[PouchDB fetch] response', error)
            reject(error)
          })
      })
      .catch(err => {
        reject(err)
      })
  })
}
