import axios from 'axios'

export default {
  namespaced: true,
  state: {
    categories: localStorage.getItem('categories')
      ? JSON.parse(localStorage.getItem('categories'))
      : false
  },
  mutations: {
    categories(state, categories) {
      state.categories = categories
    },
    id(state, id) {
      state.id = id
    },
    fetch(state, categories) {
      state.categories = categories
      localStorage.setItem('categories', JSON.stringify(categories))
    },
    reset(state) {
      state.categories = false
      localStorage.removeItem('categories')
    }
  },
  actions: {
    categories({ commit }, categories) {
      commit('categories', categories)
    },
    async fetch({ commit, state }) {
      if (state.categories === false) {
        const res = await axios('/common/cms/category/')
        commit('fetch', await res.data)
      }
    },
    reset({ commit, state }) {
      commit('reset', state)
    }
  },
  getters: {
    categories: state => {
      return state.categories
    },
    breadcrumb: state => category_id => {
      if (state.categories === false) {
        return false
      }
      var getBreadcrumbs = function(items, id) {
        for (const item of items) {
          // console.log({name: item.title}) // eslint-disable-line no-console
          if (item.id === id) {
            // console.log('找到目标节点', {id, name: item.title}) // eslint-disable-line no-console
            return [item]
          }
          if (
            !item.children ||
            !Array.isArray(item.children) ||
            item.children.length === 0
          ) {
            // console.log('无子节点', {name: item.title}) // eslint-disable-line no-console
            continue
          }
          // console.log('子节点', {children: item.children}) // eslint-disable-line no-console
          let result = getBreadcrumbs(item.children, id)
          // console.log('子节点结果', {result, name: item.title}) // eslint-disable-line no-console
          if (Array.isArray(result)) {
            return [item].concat(result)
          }
        }
        return false
      }
      let categories = state.categories
      return getBreadcrumbs(Object.values(categories), category_id)
    }
  }
}
