import ejs from 'ejs'

export default {
  install: Vue => {
    Vue.mixin({
      methods: {
        async $getHtml(payload) {
          let { template, ...data } = payload
          template = template ?? this.$route.name
          let pathes = template.split('.')
          const docId = `templates:${pathes.join('.')}`
          let result = await this.$store.dispatch(
            'Common/Daemon/getDocumentById',
            {
              dbName: 'config',
              key: docId
            }
          )
          if (!result?.template) {
            return ''
          }
          let html = ejs.render(result.template, data)
          return html
        }
      }
    })
  }
}
