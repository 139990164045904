import * as clipboard from 'clipboard-polyfill/text'
import { Message, Notice } from 'view-design'

const copy = (content, success, failed) => {
  clipboard.writeText(content).then(
    () => {
      Message.success({
        background: true,
        content: success
      })
    },
    () => {
      Notice.error({
        title: failed
      })
    }
  )
}

export default {
  install: Vue => {
    Object.defineProperties(Vue.prototype, {
      $copy: {
        get: () => {
          return copy
        }
      }
    })
  }
}
