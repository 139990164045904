// 引入一堆兼容
import 'url-search-params-polyfill'

import Vue from 'vue'
import BootstrapVue from 'bootstrap-vue'
import App from '@/App'
import router from '@/router'
import axios from '@/core/auth'
import iviewFixer from '@/core/mixins/iview'
import store from '@/store'
import VeeValidate, { Validator } from 'vee-validate'
import zh from 'vee-validate/dist/locale/zh_CN'
import localForage from 'localforage'
import 'localforage-getitems'
import ViewUI from 'view-design'
import * as directives from './directives'
import * as filters from './filters'
// import VueSilentbox from 'vue-silentbox'

import dayjs from 'dayjs'
import 'dayjs/locale/zh-cn'
import relativeTime from 'dayjs/plugin/relativeTime'
import duration from 'dayjs/plugin/duration'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import isBetween from 'dayjs/plugin/isBetween'
import customParseFormat from 'dayjs/plugin/customParseFormat'
dayjs.locale('zh-cn')
dayjs.extend(relativeTime)
dayjs.extend(duration)
dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.extend(isBetween)
dayjs.extend(customParseFormat)

import Swal from 'sweetalert2'

// iView 4.x
Vue.use(ViewUI)
// Vue.use(VueSilentbox)

Vue.use(VeeValidate, {
  events: 'input|change',
  fieldsBagName: 'formFields'
})
Validator.localize('zh', zh)

Vue.use(BootstrapVue)
Vue.mixin(iviewFixer)

Vue.prototype.$storage = {}
// eslint-disable-next-line no-console
console.log('store.state.default', store.state.default)
for (let name in store.state.default.storage.localforage) {
  Vue.prototype.$storage[name] = localForage.createInstance(
    store.state.default.storage.localforage[name]
  )
}

// 从网页上读入环境变量
let globalEnv = process.env.CONFIGS
let metas = document.querySelectorAll('meta[type="env"]')
if (metas.length > 0) {
  let config = {}
  for (let meta of metas) {
    let name = meta.getAttribute('name')
    let value = meta.getAttribute('content')
    let encoding = meta.getAttribute('encoding')
    if (name && value) {
      config[name] = encoding
        ? Buffer.from(value, 'hex').toString().trim()
        : value
    }
  }
  // eslint-disable-next-line no-console
  // console.warn('injected config', config)
  globalEnv = Object.assign(globalEnv, config)
}

let appEncrypt = globalEnv.APP_ENCRYPT

if (globalEnv.APP_MODE == 'development') {
  let matches = document.cookie.match(/app-encrypt=([A-Z0-9]+)/)
  appEncrypt = matches?.[1] ?? appEncrypt
  // eslint-disable-next-line no-console
  // console.warn('document.cookie', matches, appEncrypt)
}

if (appEncrypt) {
  // eslint-disable-next-line no-console
  // console.warn(appEncrypt)
  axios.defaults = axios.defaults || {}
  axios.defaults.encryptModule = {
    algorithm: appEncrypt
  }
  if (['SM4', 'SM2'].includes(appEncrypt)) {
    import('@chaozhou/sm4-wasm').then(sm => {
      axios.defaults.encryptModule = {
        algorithm: appEncrypt,
        transformer: sm
      }
      // eslint-disable-next-line no-console
      // console.warn('@chaozhou/sm4-wasm', axios.defaults)
      Vue.prototype.$axios = axios
    })
  }
}

// eslint-disable-next-line no-console
console.warn('appEncrypt', Vue.prototype.$axios)
Vue.prototype.$axios = Vue.prototype.$axios || axios

axios.defaults.baseURL = globalEnv.APP_ENDPOINT.endsWith('/')
  ? globalEnv.APP_ENDPOINT
  : `${globalEnv.APP_ENDPOINT}/`
globalEnv.APP_COUCHDB =
  globalEnv.APP_COUCHDB.endsWith('/') || globalEnv.APP_COUCHDB.endsWith('_')
    ? globalEnv.APP_COUCHDB
    : `${globalEnv.APP_COUCHDB}/`
Vue.prototype.$globalEnv = globalEnv

Vue.prototype.$dayjs = dayjs
Vue.prototype.$swal = Swal.mixin({
  customClass: {
    confirmButton: 'btn btn-success mx-1',
    denyButton: 'btn btn-danger mx-1',
    cancelButton: 'btn btn-secondary mx-1'
  },
  allowOutsideClick: false,
  buttonsStyling: false
})
Vue.prototype.$toast = Swal.mixin({
  toast: true,
  position: 'top-end',
  showConfirmButton: false,
  timer: 3000,
  timerProgressBar: true,
  didOpen: toast => {
    toast.addEventListener('mouseenter', Swal.stopTimer)
    toast.addEventListener('mouseleave', Swal.resumeTimer)
  }
})
Vue.prototype.$forceCompute = function (
  computedName,
  forceUpdate /* default: true */
) {
  if (this._computedWatchers[computedName]) {
    this._computedWatchers[computedName].run()
    if (forceUpdate || typeof forceUpdate == 'undefined') this.$forceUpdate()
  }
}
// 若是没有开启Devtools工具，在开发环境中开启，在生产环境中关闭
if (process.env.NODE_ENV != 'production') {
  Vue.config.devtools = true
  Vue.config.productionTip = false
} else {
  Vue.config.devtools = false
  Vue.config.productionTip = true
}

// 打开v-directive
Object.keys(directives).map(key => {
  Vue.directive(key, directives[key])
})
// 启用过滤器
Object.keys(filters).map(key => {
  Vue.filter(key, filters[key])
})

// 安装全局插件
import plugins from '@/plugins'
Vue.use(plugins)

// 引入文字资源文件
import VueI18n from 'vue-i18n'
Vue.use(VueI18n)
let locale = globalEnv.APP_ID ?? 'fallback'

store.dispatch('Common/Daemon/initialize')
store.commit('Common/Daemon/setCouchDBEndpoint', globalEnv.APP_COUCHDB)

let messages = {}
messages['fallback'] = {}
messages[locale] = {}
messages['default'] = {}
const i18n = new VueI18n({
  locale,
  fallbackLocale: ['fallback', 'default'],
  messages,
  silentTranslationWarn: true
})
window.vue = new Vue({
  i18n,
  router,
  store,
  render: h => h(App)
}).$mount('#app')
