import jwtDecode from 'jwt-decode'
import dayjs from 'dayjs'
import EventBus from '@/core/eventbus'

export default {
  namespaced: true,
  state: {
    jwt: null,
    tik: null
  },
  mutations: {
    login(state, jwtToken) {
      const jwt = jwtDecode(jwtToken.substr(7))
      state.jwt = jwt
      const iat = jwt.iat * 1e3
      const exp = jwt.exp * 1e3
      const ts = +new Date()
      const tsCorrection = ts - iat
      localStorage.setItem('jwtTtlMins', (exp - iat) / 60e3)
      localStorage.setItem('jwtLastLoginedAt', ts)
      localStorage.setItem('jwtToken', jwtToken)
      localStorage.setItem('jwtTokenIssueddAt', ts)
      localStorage.setItem('jwtTokenExpiredAt', exp)
      localStorage.setItem('requestedAt', ts)
      localStorage.setItem('timestampCorrection', tsCorrection)
    },
    logout(state) {
      state.jwt = null
      state.tik = null
    },
    tik(state) {
      let now = +new Date()
      now = now - Number(localStorage.getItem('timestampCorrection') || 0)
      state.tik = now
      localStorage.setItem('requestedAt', now)
    },
    refresh(state, jwtToken) {
      const jwt = jwtDecode(jwtToken.substr(7))
      state.jwt = jwt
      const iat = jwt.iat * 1e3
      const exp = jwt.exp * 1e3
      const ts = +new Date()
      const outputInfo = {
        当前时间: dayjs(ts).format('YYYY-MM-DD HH:mm:ss'),
        授权时间: dayjs(iat).format('YYYY-MM-DD HH:mm:ss'),
        过期时间: dayjs(exp).format('YYYY-MM-DD HH:mm:ss')
      }

      // eslint-disable-next-line no-console
      console.table(outputInfo)
      localStorage.setItem('jwtToken', jwtToken)
      localStorage.setItem('jwtTokenIssueddAt', ts)
      localStorage.setItem('jwtTokenExpiredAt', exp)
    },
    reset(state) {
      let channel = this.state?.Common?.Daemon.channel ?? null
      if (channel) {
        channel.postMessage({ eventType: 'reset', source: 'authorization' })
        EventBus.$emit('authorization/reset', null)
        // eslint-disable-next-line no-console
        console.warn('Authorization Reset')
      }
      localStorage.removeItem('jwtLastLoginedAt')
      localStorage.removeItem('jwtToken')
      localStorage.removeItem('jwtTokenIssueddAt')
      localStorage.removeItem('jwtTokenExpiredAt')
      localStorage.removeItem('requestedAt')
      localStorage.removeItem('timestampCorrection')
      state.jwt = null
      state.tik = null
    }
  },
  actions: {
    login({ commit }, jwtToken) {
      commit('login', jwtToken)
    },
    tik({ commit }) {
      commit('tik')
    },
    logout({ dispatch }) {
      this._vm
        .$axios('/web/auth/logout/')
        .then(() => {
          console.log('用户退出登录') // eslint-disable-line no-console
        })
        .catch(error => {
          console.error(error) // eslint-disable-line no-console
        })
        .then(() => {
          dispatch('reset')
        })
    },
    // 更新口令牌
    async refreshToken({ commit, rootState }) {
      const leader = rootState.Common.Daemon.leader
      this._vm.$warn('refreshToken 当前页面是否为Leader', leader)
      if (!leader) {
        return
      }
      this._vm.$warn('refreshToken 开始刷新口令牌')
      await this._vm
        .$axios('/web/auth/refresh/')
        .then(response => {
          commit('refresh', response.data.token)
        })
        .catch(error => {
          console.error(error) // eslint-disable-line no-console
        })
    },
    reset({ commit }) {
      // eslint-disable-next-line no-console
      // console.log('清空内存以及缓存的数据')
      commit(
        'SiteStore/reset',
        {},
        {
          root: true
        }
      )
      commit(
        'UserStore/reset',
        {},
        {
          root: true
        }
      )
      commit('logout')
      localStorage.clear()
    }
  },
  getters: {
    token: () => localStorage.getItem('jwtToken'),
    valid: () => {
      let jwtTokenExpiredAt = localStorage.getItem('jwtTokenExpiredAt') || 0

      if (!jwtTokenExpiredAt) {
        return false
      }

      let now = +new Date()
      now = now - Number(localStorage.getItem('timestampCorrection') || 0)

      return now < jwtTokenExpiredAt
    }
  }
}
