var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.presets && _vm.presets.length
    ? _c(
        "b-row",
        { staticClass: "border-bottom mb-3" },
        [
          _c(
            "b-col",
            { attrs: { cols: "12" } },
            [
              _c(
                "b-form-group",
                {
                  attrs: {
                    label: "预置模版",
                    "label-cols": "2",
                    "label-for": "preset"
                  }
                },
                [
                  _c(
                    "div",
                    { staticClass: "d-flex justify-start items-center w-full" },
                    [
                      _c(
                        "Select",
                        {
                          staticClass: "flex-1",
                          attrs: {
                            placeholder: "请选择预置模版",
                            clearable: ""
                          },
                          model: {
                            value: _vm.preset,
                            callback: function($$v) {
                              _vm.preset = $$v
                            },
                            expression: "preset"
                          }
                        },
                        _vm._l(_vm.presets, function(item) {
                          return _c(
                            "Option",
                            { key: item.id, attrs: { value: item.id } },
                            [
                              _vm._v(
                                "\n            " +
                                  _vm._s(item.name) +
                                  "（" +
                                  _vm._s(item.creator.name) +
                                  "）\n          "
                              )
                            ]
                          )
                        }),
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "b-button",
                        {
                          staticClass: "float-right mr-2",
                          attrs: {
                            variant: "secondary",
                            disabled: !_vm.preset
                          },
                          on: { click: _vm.applyPreset }
                        },
                        [_vm._v("\n          应用\n        ")]
                      )
                    ],
                    1
                  )
                ]
              )
            ],
            1
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }