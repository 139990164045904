const getColor = color => {
  switch (color) {
    case 'red':
      return 'background-color: #e0005a ; color: #ffffff ; font-weight: bold ; padding: 4px ;'
    case 'yellow':
      return 'background-color: #EFCD90 ; color: #000000 ; font-weight: bold ; padding: 4px ;'
    case 'green':
      return 'background-color: green ; color: #ffffff ; font-weight: bold ; padding: 4px ;'
    default:
      return 'background-color: #0DB21A ; color: #000000 ; font-weight: bold ; padding: 4px ;'
  }
}

const log = (...args) => {
  // eslint-disable-next-line no-console
  console.log(...args)
}

const info = (...args) => {
  let [first, ...rest] = args
  // eslint-disable-next-line no-console
  console.log(`%c${first}`, getColor('green'), ...rest)
}

const warn = (...args) => {
  let [first, ...rest] = args
  // eslint-disable-next-line no-console
  console.log(`%c${first}`, getColor('yellow'), ...rest)
}

const error = (...args) => {
  let [first, ...rest] = args
  // eslint-disable-next-line no-console
  console.log(`%c${first}`, getColor('red'), ...rest)
}

export default {
  install: Vue => {
    Object.defineProperties(Vue.prototype, {
      $log: {
        get: () => {
          return log
        }
      },
      $warn: {
        get: () => {
          return warn
        }
      },
      $error: {
        get: () => {
          return error
        }
      },
      $info: {
        get: () => {
          return info
        }
      }
    })
  }
}
