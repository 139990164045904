var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.payload && _vm.settings
    ? _c(
        "b-modal",
        {
          staticClass: "plugin-editor-modal",
          attrs: {
            static:
              _vm.settings.meta &&
              typeof _vm.settings.meta.static != "undefined"
                ? _vm.settings.meta.static
                : true,
            lazy: true,
            size:
              _vm.payload.size ||
              (_vm.settings && _vm.settings.meta && _vm.settings.meta.size
                ? _vm.settings.meta.size
                : "lg"),
            "no-close-on-esc":
              _vm.settings.meta &&
              _vm.settings.meta.modal &&
              typeof _vm.settings.meta.modal["no-close-on-esc"] === "boolean"
                ? _vm.settings.meta.modal["no-close-on-esc"]
                : true,
            "no-close-on-backdrop":
              _vm.settings.meta &&
              _vm.settings.meta.modal &&
              typeof _vm.settings.meta.modal["no-close-on-backdrop"] ===
                "boolean"
                ? _vm.settings.meta.modal["no-close-on-backdrop"]
                : true,
            "body-class": "common-property-editor p-0"
          },
          on: { shown: _vm.onShown, hidden: _vm.onHidden },
          model: {
            value: _vm.modalShow,
            callback: function($$v) {
              _vm.modalShow = $$v
            },
            expression: "modalShow"
          }
        },
        [
          _vm.initializing
            ? [
                _vm.settings
                  ? _c(
                      "div",
                      { attrs: { slot: "modal-title" }, slot: "modal-title" },
                      [_vm._v("加载中...")]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "w-100 text-right",
                    attrs: { slot: "modal-footer" },
                    slot: "modal-footer"
                  },
                  [
                    _c(
                      "b-button",
                      {
                        attrs: { variant: "secondary" },
                        on: {
                          click: function($event) {
                            return _vm.hide(true)
                          }
                        }
                      },
                      [_vm._v(" 取消 ")]
                    )
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass:
                      "w-100 justify-center items-center flex position-relative",
                    staticStyle: { height: "150px" }
                  },
                  [_c("Spin", { attrs: { size: "large", fix: "" } })],
                  1
                )
              ]
            : [
                _vm.settings
                  ? _c(
                      "div",
                      { attrs: { slot: "modal-title" }, slot: "modal-title" },
                      [
                        _c("p", { staticClass: "my-0" }, [
                          _c("span", [
                            _c("i", {
                              staticClass: "fal",
                              class: _vm.icon
                                ? _vm.icon
                                : _vm.payload.type === "create"
                                ? "fa-plus"
                                : "fa-pencil"
                            }),
                            _vm._v(
                              "\n          " + _vm._s(_vm.title) + "\n        "
                            )
                          ]),
                          _vm._v(" "),
                          _c("br"),
                          _vm._v(" "),
                          _c("small", {
                            staticClass: "text-muted",
                            domProps: {
                              innerHTML: _vm._f("htmlXss")(_vm.description)
                            }
                          })
                        ])
                      ]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "w-100",
                    class:
                      _vm.settings &&
                      _vm.settings.meta &&
                      _vm.settings.meta.footer &&
                      _vm.settings.meta.footer.className
                        ? _vm.settings.meta.footer.className
                        : "flex flex-row justify-end items-center",
                    attrs: { slot: "modal-footer" },
                    slot: "modal-footer"
                  },
                  [
                    _vm.settings.meta &&
                    _vm.settings.meta.footer &&
                    _vm.settings.meta.footer.buttons
                      ? [
                          _vm._l(_vm.settings.meta.footer.buttons, function(
                            button
                          ) {
                            return [
                              button.type === "wizard"
                                ? _c(
                                    "b-button",
                                    {
                                      key: "button-" + button.id,
                                      attrs: {
                                        variant: button.variant
                                          ? button.variant
                                          : "primary",
                                        disabled: _vm.isDisabledButton(button)
                                      },
                                      on: {
                                        click: function($event) {
                                          return _vm.doButtonCallback(button)
                                        }
                                      }
                                    },
                                    [
                                      _c("i", { class: button.icon }),
                                      _vm._v(
                                        " " +
                                          _vm._s(button.text) +
                                          "\n          "
                                      )
                                    ]
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              button.type === "group"
                                ? _c(
                                    "b-button-group",
                                    { key: "button-" + button.id },
                                    _vm._l(button.children, function(
                                      subButton
                                    ) {
                                      return _c(
                                        "b-button",
                                        {
                                          key: "button-" + subButton.id,
                                          attrs: {
                                            variant: subButton.variant
                                              ? subButton.variant
                                              : "primary",
                                            disabled: _vm.isDisabledButton(
                                              subButton
                                            )
                                          },
                                          on: {
                                            click: function($event) {
                                              return _vm.doButtonCallback(
                                                subButton
                                              )
                                            }
                                          }
                                        },
                                        [
                                          _c("i", { class: subButton.icon }),
                                          _vm._v(
                                            " " +
                                              _vm._s(subButton.text) +
                                              "\n            "
                                          )
                                        ]
                                      )
                                    }),
                                    1
                                  )
                                : _vm._e()
                            ]
                          })
                        ]
                      : [
                          _vm.settings.meta.cancelButton !== false
                            ? _c(
                                "b-button",
                                {
                                  staticClass: "mr-2",
                                  attrs: { variant: "secondary" },
                                  on: {
                                    click: function($event) {
                                      return _vm.hide(true)
                                    }
                                  }
                                },
                                [_vm._v("\n          取消\n        ")]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.settings &&
                          _vm.settings.meta &&
                          _vm.settings.meta.preset &&
                          _vm.settings.meta.preset.id &&
                          _vm.ability("preset." + _vm.settings.meta.preset.id)
                            ? _c(
                                "b-button-group",
                                { staticClass: "float-right" },
                                [
                                  _c(
                                    "b-dropdown",
                                    {
                                      attrs: {
                                        right: "",
                                        split: "",
                                        variant: "primary",
                                        disabled: _vm.processing
                                      },
                                      on: { click: _vm.onSubmit },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "button-content",
                                            fn: function() {
                                              return [
                                                _vm.processing
                                                  ? _c("span", [
                                                      _c("i", {
                                                        staticClass:
                                                          "fal fa-spinner fa-spin"
                                                      }),
                                                      _vm._v(
                                                        " 保存中\n              "
                                                      )
                                                    ])
                                                  : _c("span", [_vm._v("确定")])
                                              ]
                                            },
                                            proxy: true
                                          }
                                        ],
                                        null,
                                        false,
                                        2295678506
                                      )
                                    },
                                    [
                                      _vm._v(" "),
                                      _c(
                                        "b-dropdown-item",
                                        { on: { click: _vm.savePresets } },
                                        [
                                          _vm._v(
                                            "\n              当前表单内容保存为预置模版\n            "
                                          )
                                        ]
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            : _c(
                                "b-button",
                                {
                                  attrs: {
                                    variant: "primary",
                                    disabled: _vm.processing
                                  },
                                  on: { click: _vm.onSubmit }
                                },
                                [
                                  _vm.processing
                                    ? _c("span", [
                                        _c("i", {
                                          staticClass: "fal fa-spinner fa-spin"
                                        }),
                                        _vm._v(" 保存中")
                                      ])
                                    : _c("span", [_vm._v("确定")])
                                ]
                              )
                        ]
                  ],
                  2
                ),
                _vm._v(" "),
                _vm.propertyComponents
                  ? _c(
                      "b-form",
                      {
                        class:
                          _vm.settings &&
                          _vm.settings.meta &&
                          _vm.settings.meta.form &&
                          _vm.settings.meta.form.className
                            ? _vm.settings.meta.form.className
                            : "container-fluid components-container mt-3",
                        attrs: { novalidate: "" },
                        on: {
                          submit: function($event) {
                            $event.preventDefault()
                            return _vm.onSubmit.apply(null, arguments)
                          }
                        }
                      },
                      [
                        _vm.settings &&
                        _vm.settings.meta &&
                        _vm.settings.meta.preset &&
                        _vm.settings.meta.preset.id &&
                        _vm.ability("preset." + _vm.settings.meta.preset.id)
                          ? _c("Preset", {
                              ref: "preset",
                              staticClass: "mt-3",
                              attrs: {
                                name: "出库申请单",
                                "route-name": _vm.settings.meta.preset.id
                              },
                              on: { input: _vm.applyPreset }
                            })
                          : _vm._e(),
                        _vm._v(" "),
                        _c(
                          "b-row",
                          [
                            _vm.settings.meta.wizard
                              ? _c(
                                  "b-col",
                                  {
                                    attrs: {
                                      cols: _vm.settings.meta.wizard.cols
                                        ? _vm.settings.meta.wizard.cols
                                        : "12"
                                    }
                                  },
                                  [
                                    _vm.settings.meta.wizard &&
                                    _vm.settings.meta.wizard.steps
                                      ? _c(
                                          "b-row",
                                          { staticClass: "my-3" },
                                          [
                                            _c(
                                              "b-col",
                                              {
                                                staticClass: "pb-3",
                                                class:
                                                  _vm.settings.meta.wizard &&
                                                  _vm.settings.meta.wizard
                                                    .cols == 12
                                                    ? "border-bottom"
                                                    : "",
                                                attrs: { cols: "12" }
                                              },
                                              [
                                                _c(
                                                  "Steps",
                                                  {
                                                    attrs: {
                                                      current: _vm.currentStep,
                                                      status:
                                                        _vm.currentStepStatus,
                                                      direction:
                                                        _vm.settings.meta.wizard
                                                          .cols == 12
                                                          ? "horizontal"
                                                          : "vertical"
                                                    }
                                                  },
                                                  _vm._l(
                                                    _vm.settings.meta.wizard
                                                      .steps,
                                                    function(step, index) {
                                                      return _c("Step", {
                                                        key: "step-" + index,
                                                        attrs: {
                                                          title: step.title,
                                                          content: step.content
                                                        }
                                                      })
                                                    }
                                                  ),
                                                  1
                                                )
                                              ],
                                              1
                                            )
                                          ],
                                          1
                                        )
                                      : _vm._e()
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.settings.meta.collapse
                              ? [
                                  _c(
                                    "b-col",
                                    {
                                      style:
                                        _vm.settings.meta.collapse &&
                                        _vm.settings.meta.collapse.cols &&
                                        _vm.settings.meta.collapse.cols != 12
                                          ? "background-color: #F7F7F7;"
                                          : "",
                                      attrs: {
                                        cols:
                                          _vm.settings.meta.collapse &&
                                          _vm.settings.meta.collapse.cols
                                            ? 12 -
                                              _vm.settings.meta.collapse.cols
                                            : "12"
                                      }
                                    },
                                    _vm._l(
                                      _vm.settings.meta.collapse.groups,
                                      function(group) {
                                        return _c(
                                          "div",
                                          { key: "" + group.id },
                                          [
                                            _c(
                                              "h6",
                                              {
                                                staticClass:
                                                  "cursor-pointer my-3",
                                                class: {
                                                  "text-danger": _vm.groupHasError(
                                                    group.id
                                                  )
                                                },
                                                on: {
                                                  click: function($event) {
                                                    return _vm.toggleGroup(
                                                      group.id
                                                    )
                                                  }
                                                }
                                              },
                                              [
                                                _c("i", {
                                                  staticClass: "fas",
                                                  class: _vm.expanded[group.id]
                                                    ? "fa-angle-down"
                                                    : "fa-angle-right"
                                                }),
                                                _vm._v(" "),
                                                _c("span", [
                                                  _vm._v(
                                                    _vm._s(group.name || "") +
                                                      " "
                                                  )
                                                ])
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "b-collapse",
                                              {
                                                staticClass: "mb-3 px-3",
                                                attrs: {
                                                  id:
                                                    "collapse-group-" + group.id
                                                },
                                                model: {
                                                  value: _vm.expanded[group.id],
                                                  callback: function($$v) {
                                                    _vm.$set(
                                                      _vm.expanded,
                                                      group.id,
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "expanded[group.id]"
                                                }
                                              },
                                              [
                                                _vm.settings &&
                                                _vm.settings.fields
                                                  ? _c(
                                                      "b-row",
                                                      [
                                                        _vm._l(
                                                          _vm.settings.fields,
                                                          function(field) {
                                                            return [
                                                              field._meta &&
                                                              field._meta
                                                                .collapse &&
                                                              field._meta
                                                                .collapse
                                                                .group ===
                                                                group.id
                                                                ? _c(
                                                                    "b-col",
                                                                    {
                                                                      key:
                                                                        "property-" +
                                                                        field.id,
                                                                      class:
                                                                        (field
                                                                          ._meta
                                                                          .layout
                                                                          .className ||
                                                                          "") +
                                                                        " component-container",
                                                                      attrs: {
                                                                        cols:
                                                                          field
                                                                            ._meta
                                                                            .layout
                                                                            .cols
                                                                      }
                                                                    },
                                                                    [
                                                                      _c(
                                                                        _vm
                                                                          .propertyComponents[
                                                                          field
                                                                            .id
                                                                        ],
                                                                        _vm._g(
                                                                          {
                                                                            key:
                                                                              "common-form-" +
                                                                              field.id +
                                                                              "-" +
                                                                              _vm.incrementId,
                                                                            ref:
                                                                              field.id,
                                                                            refInFor: true,
                                                                            tag:
                                                                              "component",
                                                                            attrs: {
                                                                              "label-cols":
                                                                                typeof field
                                                                                  ._meta
                                                                                  .layout
                                                                                  .labelCols ===
                                                                                "undefined"
                                                                                  ? 12
                                                                                  : field
                                                                                      ._meta
                                                                                      .layout
                                                                                      .labelCols,
                                                                              properties:
                                                                                _vm.properties,
                                                                              "property-id":
                                                                                field.id,
                                                                              "form-group-class":
                                                                                field
                                                                                  ._meta
                                                                                  .className &&
                                                                                field
                                                                                  ._meta
                                                                                  .className
                                                                                  .formGroupClass
                                                                                  ? field
                                                                                      ._meta
                                                                                      .className
                                                                                      .formGroupClass
                                                                                  : null
                                                                            },
                                                                            model: {
                                                                              value:
                                                                                _vm.form,
                                                                              callback: function(
                                                                                $$v
                                                                              ) {
                                                                                _vm.form = $$v
                                                                              },
                                                                              expression:
                                                                                "form"
                                                                            }
                                                                          },
                                                                          field.listeners
                                                                        )
                                                                      )
                                                                    ],
                                                                    1
                                                                  )
                                                                : _vm._e()
                                                            ]
                                                          }
                                                        )
                                                      ],
                                                      2
                                                    )
                                                  : _vm._e()
                                              ],
                                              1
                                            )
                                          ],
                                          1
                                        )
                                      }
                                    ),
                                    0
                                  )
                                ]
                              : [
                                  _c(
                                    "b-col",
                                    {
                                      style:
                                        _vm.settings.meta.wizard &&
                                        _vm.settings.meta.wizard.cols &&
                                        _vm.settings.meta.wizard.cols != 12
                                          ? "background-color: #F7F7F7;"
                                          : "",
                                      attrs: {
                                        cols:
                                          _vm.settings.meta.wizard &&
                                          _vm.settings.meta.wizard.cols
                                            ? 12 - _vm.settings.meta.wizard.cols
                                            : "12"
                                      }
                                    },
                                    [
                                      _vm.settings && _vm.settings.fields
                                        ? _c(
                                            "b-row",
                                            [
                                              _vm._l(
                                                _vm.settings.fields,
                                                function(field) {
                                                  return [
                                                    _vm.fieldOfCurrentStep(
                                                      field
                                                    )
                                                      ? _c(
                                                          "b-col",
                                                          {
                                                            key:
                                                              "property-" +
                                                              field.id,
                                                            class:
                                                              (field._meta
                                                                .layout
                                                                .className ||
                                                                "") +
                                                              " component-container",
                                                            attrs: {
                                                              cols:
                                                                field._meta
                                                                  .layout.cols
                                                            }
                                                          },
                                                          [
                                                            _c(
                                                              _vm
                                                                .propertyComponents[
                                                                field.id
                                                              ],
                                                              _vm._g(
                                                                {
                                                                  key:
                                                                    "common-form-" +
                                                                    field.id +
                                                                    "-" +
                                                                    _vm.incrementId,
                                                                  ref: field.id,
                                                                  refInFor: true,
                                                                  tag:
                                                                    "component",
                                                                  attrs: {
                                                                    "label-cols":
                                                                      typeof field
                                                                        ._meta
                                                                        .layout
                                                                        .labelCols ===
                                                                      "undefined"
                                                                        ? 12
                                                                        : field
                                                                            ._meta
                                                                            .layout
                                                                            .labelCols,
                                                                    properties:
                                                                      _vm.properties,
                                                                    "property-id":
                                                                      field.id,
                                                                    "form-group-class":
                                                                      field
                                                                        ._meta
                                                                        .className &&
                                                                      field
                                                                        ._meta
                                                                        .className
                                                                        .formGroupClass
                                                                        ? field
                                                                            ._meta
                                                                            .className
                                                                            .formGroupClass
                                                                        : null
                                                                  },
                                                                  model: {
                                                                    value:
                                                                      _vm.form,
                                                                    callback: function(
                                                                      $$v
                                                                    ) {
                                                                      _vm.form = $$v
                                                                    },
                                                                    expression:
                                                                      "form"
                                                                  }
                                                                },
                                                                field.listeners
                                                              )
                                                            )
                                                          ],
                                                          1
                                                        )
                                                      : _vm._e()
                                                  ]
                                                }
                                              )
                                            ],
                                            2
                                          )
                                        : _vm._e()
                                    ],
                                    1
                                  )
                                ]
                          ],
                          2
                        )
                      ],
                      1
                    )
                  : _vm._e()
              ]
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }